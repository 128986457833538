import { ThemeProvider } from "styled-components";
import { theme } from "src/styles/Theme";
import { GlobalStyles } from "src/styles/Global";
import store from "src/stores";
import { ReactReduxContext } from "react-redux";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import AllRoutes from "./routes";
import "./index.css";
import SessionTimeout from "./components/common/SessionTimeout";
import { useEffect } from "react";
import { helperService } from "./services/helperService";

function App() {
  // useEffect(() => {
  //   if (window.location.href.indexOf("cko-session-id=") === -1) {
  //     const prePaymentData = helperService.getItem('prePaymentData');
  //     const selectedSlot = helperService.getItem('selectedSlot');
  //     if(prePaymentData && selectedSlot){
  //       return
  //     }
  //     const invalidateSession = helperService.getItem('invalidateSession')
  //     if (invalidateSession) {
  //       window.localStorage.clear()
  //       window.location.reload()
  //     } else {
  //       if(window.location.href.indexOf("login") === -1 && window.location.href.indexOf("otp") === -1){
  //         helperService.setItem("invalidateSession", true)
  //       }
  //     }
  //   }
  // }, [])

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Provider store={store} context={ReactReduxContext}>
        <SessionTimeout timeout={20 * 60 * 1000} />
        {/* 20 minutes */}
        <BrowserRouter>
          <AllRoutes />
        </BrowserRouter>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
