import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  loadingStart: null,
  loadingStop: null,
  getSavedCards: null,
  saveAddCardResponse: ["payload"],
  verifyPaymentResponse: ["payload"],
  addCard: ["payload"],
  getApplePayToken: ["payload"],
  verifyAndPayment: ["payload"],
  processPayment: ["payload"],
  saveCards: ["payload"],
  walletTopup: ["payload"],
  recordTransferRequest: ["payload"],
  updateCurrentMonthUsage: ["payload"],
  verifyPayByBalanceRequest: ["payload"],
  verifyPayByBalance: ["payload"],

  getMerchantPaymentDetails: ["payload"],
  getMerchantPaymentDetailsLoading: null,
  getMerchantPaymentDetailsFailure: null,
  getMerchantPaymentDetailsSuccess: ["payload"],

  partnerPaymentAuthorization: ["payload"],
  partnerPaymentAuthorizationLoading: null,
  partnerPaymentAuthorizationFailure: null,
  partnerPaymentAuthorizationSuccess: ["payload"],

  getPartnerPaymentDetails: ["payload"],
  getPartnerPaymentDetailsLoading: null,
  getPartnerPaymentDetailsFailure: null,
  getPartnerPaymentDetailsSuccess: ["payload"],
});

export const CheckoutTypes = Types;
export default Creators;
