import { styled, Button } from "@mui/material";

export const TooltipButton = styled(Button)(({ theme }) => ({
  width: "100%",
  padding: "10px",
  fontFamily: 'Light',
  textTransform: "none",
  fontWeight: 400,
  fontSize: "20px",
  borderRadius: "16px",
  textDecoration: "none",
  color: "black",
}));
