import axios from "axios";
import { apiClientService } from "./apiClientService";
import { helperService } from "src/services/helperService";
import apiConstants from "src/config/env";

console.log(apiConstants);
const { apiClient, in200s } = apiClientService;

let base_url = apiConstants.BASE_URL;

function initiateLogin(params) {
  let url = base_url + "/orm/user/login/initiate";

  return apiClient
    .post(url, params)
    .then((response) => {
      if (in200s(response.status)) {
        return response.data;
      }
      return null;
    })
    .catch((error) => {
      console.log("initiateLogin", error);
      return null;
    });
}

const verifyLogin = async (params, customHeaders) => {
  let url = base_url + "/orm/user/login";
  return apiClient
    .post(url, params, {
      headers: {
        Authorization: helperService.getAuthToken(),
        ...customHeaders,
      },
    })
    .then((response) => {
      if (in200s(response.status)) {
        return response.data;
      }
      return null;
    })
    .catch((error) => {
      console.log("verifyLogin", error);
      return null;
    });
};

const resendOtp = async (customHeaders) => {
  let url = base_url + "/orm/user/login/otp/resend";
  return apiClient
    .get(url, {
      headers: {
        Authorization: helperService.getAuthToken(),
        ...customHeaders,
      },
    })
    .then((response) => {
      if (in200s(response.status)) {
        return response.data;
      }
      return null;
    })
    .catch((error) => {
      console.log("verifyLogin", error);
      return null;
    });
};

const updateProfile = async (params, customHeaders) => {
  let url = base_url + "/orm/user";
  return apiClient
    .put(url, params, {
      headers: {
        Authorization: helperService.getAuthToken(),
        ...customHeaders,
      },
    })
    .then((response) => {
      if (in200s(response.status)) {
        return response.data;
      }
      return null;
    })
    .catch((error) => {
      console.log("updateProfile", error);
      return null;
    });
};

const categoriesList = async () => {
  let url = base_url + `/orm/center/categories`;
  return apiClient
    .get(url)
    .then((response) => {
      if (response.url === url) {
        return response.data;
      }
      if (in200s(response.status)) {
        return response.data;
      }
      return null;
    })
    .catch((error) => {
      console.log("categoriesList", error);
      return null;
    });
};

const sessionList = async ({ categoryId }) => {
  let url = base_url + `/orm/center/${categoryId}/sessions`;
  return apiClient
    .get(url)
    .then((response) => {
      if (response.url === url) {
        return response.data;
      }
      if (in200s(response.status)) {
        return response.data;
      }
      return null;
    })
    .catch((error) => {
      console.log("sessionList", error);
      return null;
    });
};

const stylistList = async ({ categoryId, sessionId }) => {
  let url =
    base_url + `/orm/center/${categoryId}/sessions/${sessionId}/stylists`;
  return apiClient
    .get(url)
    .then((response) => {
      if (response.url === url) {
        return response.data;
      }
      if (in200s(response.status)) {
        return response.data;
      }
      return null;
    })
    .catch((error) => {
      console.log("stylistList", error);
      return null;
    });
};

const getAvailableSlots = async (params) => {
  let url = base_url + `/orm/booking/availableSlots`;
  return apiClient
    .post(url, params, {
      headers: { Authorization: helperService.getAuthToken() },
    })
    .then((response) => {
      if (in200s(response.status)) {
        return response.data;
      }
      console.log(response, "Error scenario");
      return null;
    })
    .catch((error) => {
      console.log(error, "Error scenario 1");
      return null;
    });
};

const confirmBooking = async ({ bookingId, slot, accountId }) => {
  let url =
    base_url + `/orm/booking/${bookingId}/slot/reserve/business/${accountId}`;
  return apiClient
    .post(
      url,
      { slot_time: slot },
      {
        headers: { Authorization: helperService.getAuthToken() },
      }
    )
    .then((response) => {
      console.log(response, "confirmBooking");
      if (in200s(response.status)) {
        return response.data;
      }
      return null;
    })
    .catch((error) => {
      console.log(error, "confirmBooking");
      return null;
    });
};

const cancelBooking = async ({ invoiceId, slot }) => {
  let url = base_url + `/orm/booking/invoice/${invoiceId}/cancel`;
  return apiClient
    .put(
      url,
      { slot_time: slot },
      {
        headers: { Authorization: helperService.getAuthToken() },
      }
    )
    .then((response) => {
      console.log(response, "confirmBooking");
      if (in200s(response.status)) {
        return response.data;
      }
      return null;
    })
    .catch((error) => {
      console.log(error, "confirmBooking");
      return null;
    });
};

const fetchStoreData = async () => {
  const url = base_url + `/orm/merchant/details`;
  var formData = new FormData();
  formData.append("url", apiConstants.FRONTEND_BASE_URL);
  return axios
    .post(url, formData, {
      headers: {
        Authorization: helperService.getAuthToken(),
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error, "fetchStoreData");
      return null;
    });
};

const fetchSMSBooking = (bookingId) => {
  let url = base_url + `/orm/payment/${bookingId}`;

  return apiClient
    .get(url, {
      headers: {},
    })
    .then((response) => {
      console.log(response, "bookingRefernce");
      if (in200s(response.status)) {
        return response.data;
      }
      return null;
    })
    .catch((error) => {
      console.log(error, "bookingRefernce");
      return null;
    });
};

export const AuthService = {
  initiateLogin,
  verifyLogin,
  resendOtp,
  updateProfile,
  categoriesList,
  sessionList,
  stylistList,
  getAvailableSlots,
  confirmBooking,
  fetchStoreData,
  cancelBooking,
  fetchSMSBooking,
};
