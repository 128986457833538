import { async } from "q";
import React, { useEffect, useState } from "react";
import { useCallback } from "react";
import { AuthService } from "src/services/api/authService";
import { helperService } from "src/services/helperService";

function SessionTimeout({ timeout }) {
  const [sessionTimeout, setSessionTimeout] = useState(null);

  useEffect(() => {
    // set session timeout when component mounts
    const timeoutId = setTimeout(async () => {
      localStorage.clear();
      window.location.reload();
    }, timeout);

    setSessionTimeout(timeoutId);

    // clear session timeout when component unmounts
    return () => {
      clearTimeout(sessionTimeout);
    };
  }, []);

  // reset session timeout on user activity
  const resetSessionTimeout = () => {
    clearTimeout(sessionTimeout);
    const timeoutId = setTimeout(async () => {
      localStorage.clear();
      window.location.reload();
    }, timeout);
    setSessionTimeout(timeoutId);
  };

  // listen for user activity events
  useEffect(() => {
    const events = ["mousemove", "keydown", "scroll"];
    const resetTimeout = () => {
      resetSessionTimeout();
    };

    events.forEach((event) => {
      document.addEventListener(event, resetTimeout);
    });

    return () => {
      events.forEach((event) => {
        document.removeEventListener(event, resetTimeout);
      });
    };
  }, [sessionTimeout]);

  useEffect(() => {
    const beforeUnload = () => {
      if (
        performance.navigation.type == performance.navigation.TYPE_RELOAD ||
        window.location.href.indexOf("confirm-payment") > -1 ||
        window.location.href.indexOf("payment") > -1 ||
        window.location.href.indexOf("merchant-payment-status") > -1
      ) {
        return;
      }
      if (window.location.href.indexOf("cko-session-id=") === -1) {
        helperService.removeAuthToken();
      }
    };

    window.addEventListener("beforeunload", beforeUnload);

    return () => {
      window.removeEventListener("beforeunload", beforeUnload);
    };
  }, []);

  return null;
}

export default SessionTimeout;
