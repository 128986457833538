import React from "react";
import "../payment/styles.css";
import Styles from "./styles.module.css";

const OrderTotal = ({
  price,
  showConvienience,
  error = false,
  isRTAStore = false,
}) => {
  const {
    billAmount,
    listPrice,
    taxAmount,
    discountedAmount,
    convenienceAmount,
    discountedPercentage,
    customerCarbonContribution,
    conveniencePercentage,
  } = price;
  return (
    <div className={Styles["order-total-container"]}>
      {isRTAStore ? (
        <>
          <div className={Styles["total-title-container"]}>
            <div className={Styles["total-amount"]}>
              AED {((billAmount + customerCarbonContribution) / 100).toFixed(2)}
            </div>
          </div>
          <div className={Styles["separator"]}></div>
          <div>
            <div className={Styles["total-split-container"]}>
              <div className={Styles["total-parameter"]}>Ride Fare Amount</div>
              <div className={Styles["total-parameter"]}>
                AED {(billAmount / 100).toFixed(2)}
              </div>
            </div>
            <div className={Styles["total-split-container"]}>
              <div className={Styles["total-parameter"]}>
                {" "}
                CO2e Offset Amount
              </div>
              <div className={Styles["total-parameter"]}>
                AED {(+customerCarbonContribution / 100).toFixed(2)}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={Styles["total-title-container"]}>
            <div className={Styles["total-amount"]}>
              AED {(billAmount / 100).toFixed(2)}
            </div>
          </div>
          <div className={Styles["separator"]}></div>
          <div>
            <div className={Styles["total-split-container"]}>
              <div className={Styles["total-parameter"]}>Requested amount</div>
              <div className={Styles["total-parameter"]}>
                AED {(billAmount / 100).toFixed(2)}
              </div>
            </div>
            <div className={Styles["total-split-container"]}>
              <div className={Styles["total-parameter"]}>
                Processing fee ({(conveniencePercentage ?? "0") + "%"})
              </div>
              <div className={Styles["total-parameter"]}>
                AED {(convenienceAmount / 100).toFixed(2)}
              </div>
            </div>
          </div>
        </>
      )}

      <div className={Styles["order-total-text"]}>
        Payments are not refundable
      </div>
      {!error && <div className={Styles["separator"]}></div>}
    </div>
  );
};
export default OrderTotal;
