export const INITIAL_STATE = {
  loaders: {
    generateOtpLoader: false,
    getUserInfoLoader: false,
    updateUserInfoLoader: false,
    searchUserLoader: false,
    getContactsLoader: false,
    syncContactsLoader: false,
    getContactsFromDeviceLoader: false,
    fetchSMSBookingLoader: false,
  },
  storeData: {},
  userInfo: {},
  guestdata: {},
  userSearchResults: [],
  userKycStatus: "",
  contacts: [],
  nonBalanceContacts: [],
  partnerUser: {},
  newUser: true,
  accountType: "PERSONAL",
  isAuthorized: false,
  isSignedIn: false,
  isLoadingToken: true,
  requestId: "",
  verifyPinError: {},
  verifyPresentPinError: {},
  verifyPresentPinRequestId: "",
  verifyPinSuccessful: false,
  permissionsList: [],
  appEnteredOnce: false,
  fetchSMSBooking: {},
  isBookingExpireWarningModalOpen: false,
  isBookingLoadingModalOpen: false,
};
