import { INITIAL_STATE } from "./initial_state";
import { createReducer } from "reduxsauce";
import { AuthTypes } from "./actions";

export const generateOtpSuccess = (state) => ({
  ...state,
  loaders: {
    ...state.loaders,
    generateOtpLoader: false,
  },
});

export const generateOtpFailure = (state) => ({
  ...state,
  loaders: {
    ...state.loaders,
    generateOtpLoader: false,
  },
});

export const generateOtpLoading = (state) => ({
  ...state,
  loaders: {
    ...state.loaders,
    generateOtpLoader: true,
  },
});
export const generateOtpLoadingStop = (state) => ({
  ...state,
  loaders: {
    ...state.loaders,
    generateOtpLoader: false,
  },
});

export const updateUserInfoSuccess = (state, { payload }) => {
  return {
    ...state,
    userInfo: payload,
    error: {},
    loaders: {
      ...state.loaders,
      updateUserInfoLoader: false,
    },
  };
};

export const updateUserInfoFailure = (state) => {
  return {
    ...state,
    userInfo: {},
    loaders: {
      ...state.loaders,
      updateUserInfoLoader: false,
    },
  };
};

export const updateUserInfoLoading = (state) => ({
  ...state,
  loaders: {
    ...state.loaders,
    updateUserInfoLoader: true,
  },
});
export const updateUserInfoLoadingStop = (state) => ({
  ...state,
  loaders: {
    ...state.loaders,
    updateUserInfoLoader: false,
  },
});

export const getUserInfoSuccess = (state, { payload }) => {
  return {
    ...state,
    userInfo: payload,
    loaders: {
      ...state.loaders,
      getUserInfoLoader: false,
    },
  };
};

export const getUserInfoFailure = (state) => {
  return {
    ...state,
    userInfo: {},
    loaders: {
      ...state.loaders,
      getUserInfoLoader: false,
    },
  };
};

export const getUserInfoLoading = (state) => ({
  ...state,
  loaders: {
    ...state.loaders,
    getUserInfoLoader: true,
  },
});

export const getUserInfoLoadingStop = (state) => ({
  ...state,
  loaders: {
    ...state.loaders,
    getUserInfoLoader: false,
  },
});

export const searchUserSuccess = (state, { payload }) => {
  return {
    ...state,
    userSearchResults: payload,
    loaders: {
      ...state.loaders,
      searchUserLoader: false,
    },
  };
};

export const resetNonBalanceContactSearchResult = (state, { payload }) => {
  return {
    ...state,
    userSearchResults: payload,
  };
};

export const clearSearchResults = (state) => {
  return {
    ...state,
    userSearchResults: [],
  };
};

export const searchUserFailure = (state) => {
  return {
    ...state,
    userSearchResults: [],
    loaders: {
      ...state.loaders,
      searchUserLoader: false,
    },
  };
};

export const searchUserLoading = (state) => ({
  ...state,
  loaders: {
    ...state.loaders,
    searchUserLoader: true,
  },
});

export const searchUserLoadingStop = (state) => ({
  ...state,
  loaders: {
    ...state.loaders,
    searchUserLoader: false,
  },
});

export const getContactsFromDeviceSuccess = (state) => {
  return {
    ...state,
    loaders: {
      ...state.loaders,
      getContactsFromDeviceLoader: false,
    },
  };
};

export const getContactsFromDeviceFailure = (state) => {
  return {
    ...state,
    loaders: {
      ...state.loaders,
      getContactsFromDeviceLoader: false,
    },
  };
};

export const getContactsFromDeviceLoading = (state) => ({
  ...state,
  loaders: {
    ...state.loaders,
    getContactsFromDeviceLoader: true,
  },
});

export const getContactsFromDeviceLoadingStop = (state) => ({
  ...state,
  loaders: {
    ...state.loaders,
    getContactsFromDeviceLoader: false,
  },
});

export const getContactsSuccess = (state, { payload }) => {
  return {
    ...state,
    contacts: payload.contacts,
    nonBalanceContacts: payload.nonBalanceContacts,
    loaders: {
      ...state.loaders,
      getContactsLoader: false,
    },
  };
};

export const updateNonBalanceContacts = (state, { payload }) => {
  return {
    ...state,
    nonBalanceContacts: payload,
  };
};

export const getContactsFailure = (state) => {
  return {
    ...state,
    contacts: [],
    loaders: {
      ...state.loaders,
      getContactsLoader: false,
    },
  };
};

export const getContactsLoading = (state) => ({
  ...state,
  loaders: {
    ...state.loaders,
    getContactsLoader: true,
  },
});

export const getContactsLoadingStop = (state) => ({
  ...state,
  loaders: {
    ...state.loaders,
    getContactsLoader: false,
  },
});

export const syncContactsSuccess = (state) => {
  return {
    ...state,
    loaders: {
      ...state.loaders,
      syncContactsLoader: false,
    },
  };
};

export const syncContactsFailure = (state) => {
  return {
    ...state,
    loaders: {
      ...state.loaders,
      syncContactsLoader: false,
    },
  };
};

export const syncContactsLoading = (state) => ({
  ...state,
  loaders: {
    ...state.loaders,
    syncContactsLoader: true,
  },
});

export const syncContactsLoadingStop = (state) => ({
  ...state,
  loaders: {
    ...state.loaders,
    syncContactsLoader: false,
  },
});

export const logoutSuccess = (state) => ({
  ...state,
});

export const logoutUserSuccess = (state) => ({
  ...state,
});

export const setNewUser = (state, { payload }) => {
  return {
    ...state,
    newUser: payload,
  };
};

export const registerFcmTokenSuccess = (state) => ({
  ...state,
});

export const registerFcmTokenFailure = (state) => ({
  ...state,
});

export const launchSessionSuccess = (state) => ({
  ...state,
});

export const launchSessionFailure = (state) => ({
  ...state,
});

export const updateAuthorizationStatus = (state, { payload }) => {
  return {
    ...state,
    isAuthorized: payload,
  };
};

export const updateSignInStatus = (state, { payload }) => {
  return {
    ...state,
    isSignedIn: payload,
  };
};

export const updateIsLoadingToken = (state, { payload }) => {
  return {
    ...state,
    isLoadingToken: payload,
  };
};

export const initiateLoginSuccess = (state, { payload }) => ({
  ...state,
  requestId: payload.requestId,
});

export const initiateLoginFailure = (state) => ({
  ...state,
});

export const verifyLoginSuccess = (state) => ({
  ...state,
});

export const storeGuestDataSuccess = (state, { payload }) => ({
  ...state,
  guestdata: payload,
});

export const storeUserDataSuccess = (state, { payload }) => ({
  ...state,
  userInfo: payload,
});

export const updateUserSuccess = (state, { payload }) => ({
  ...state,
  guestId: payload.guestId,
});

export const verifyLoginFailure = (state) => ({
  ...state,
});

export const setPinSuccess = (state) => ({
  ...state,
});

export const setPinFailure = (state) => ({
  ...state,
});

export const verifyPinSuccess = (state) => ({
  ...state,
  verifyPinError: {},
  verifyPinSuccessful: true,
});

export const verifyPinFailure = (state, { payload }) => ({
  ...state,
  verifyPinError: payload,
});

export const clearVerifyPinError = (state) => ({
  ...state,
  verifyPinError: {},
});

export const updatePinSuccess = (state) => ({
  ...state,
});

export const updatePinFailure = (state) => ({
  ...state,
});

export const checkPinExistsSuccess = (state) => ({
  ...state,
});

export const checkPinExistsFailure = (state) => ({
  ...state,
});

export const verifyPresentPinSuccess = (state, { payload }) => ({
  ...state,
  verifyPresentPinError: {},
  verifyPresentPinRequestId: payload,
});

export const verifyPresentPinFailure = (state, { payload }) => ({
  ...state,
  verifyPresentPinError: payload,
});

export const clearVerifyPresentPinError = (state) => ({
  ...state,
  verifyPresentPinError: {},
});

export const confirmNewPinSuccess = (state) => ({
  ...state,
  verifyPresentPinError: {},
  verifyPresentPinRequestId: "",
});

export const confirmNewPinFailure = (state, { payload }) => ({
  ...state,
  verifyPresentPinError: payload,
});

export const clearVerifyPinSuccess = (state) => ({
  ...state,
  verifyPinSuccessful: false,
});

export const updateUserKycStatus = (state, { payload }) => ({
  ...state,
  userKycStatus: payload,
});

export const updatePermissionsList = (state, { payload }) => ({
  ...state,
  permissionsList: payload,
});

export const setAppEnteredOnce = (state, { payload }) => ({
  ...state,
  appEnteredOnce: payload,
});

export const fetchStoreDataSuccess = (state, { payload }) => ({
  ...state,
  storeData: payload,
});

export const fetchSMSBookingSuccess = (state, { payload }) => {
  return {
    ...state,
    fetchSMSBooking: payload,
    loaders: {
      ...state.loaders,
      syncContactsLoader: false,
    },
  };
};

export const fetchSMSBookingFailure = (state) => {
  return {
    ...state,
    loaders: {
      ...state.loaders,
      syncContactsLoader: false,
    },
  };
};

export const fetchSMSBookingLoading = (state) => ({
  ...state,
  loaders: {
    ...state.loaders,
    syncContactsLoader: true,
  },
});

export const OpenBookingExpireWarningModal = (state, { payload }) => {
  return {
    ...state,
    isBookingExpireWarningModalOpen: true,
  };
};

export const CloseBookingExpireWarningModal = (state, { payload }) => {
  return {
    ...state,
    isBookingExpireWarningModalOpen: false,
  };
};

export const OpenBookingLoadingModal = (state, { payload }) => {
  return {
    ...state,
    isBookingLoadingModalOpen: true,
  };
};

export const CloseBookingLoadingModal = (state, { payload }) => {
  return {
    ...state,
    isBookingLoadingModalOpen: false,
  };
};

export const reducer = createReducer(INITIAL_STATE, {
  [AuthTypes.GENERATE_OTP_SUCCESS]: generateOtpSuccess,
  [AuthTypes.GENERATE_OTP_FAILURE]: generateOtpFailure,
  [AuthTypes.GENERATE_OTP_LOADING]: generateOtpLoading,
  [AuthTypes.GENERATE_OTP_LOADING_STOP]: generateOtpLoadingStop,

  [AuthTypes.UPDATE_USER_INFO_SUCCESS]: updateUserInfoSuccess,
  [AuthTypes.UPDATE_USER_INFO_FAILURE]: updateUserInfoFailure,
  [AuthTypes.UPDATE_USER_INFO_LOADING]: updateUserInfoLoading,
  [AuthTypes.UPDATE_USER_INFO_LOADING_STOP]: updateUserInfoLoadingStop,

  [AuthTypes.GET_USER_INFO_SUCCESS]: getUserInfoSuccess,
  [AuthTypes.GET_USER_INFO_FAILURE]: getUserInfoFailure,
  [AuthTypes.GET_USER_INFO_LOADING]: getUserInfoLoading,
  [AuthTypes.GET_USER_INFO_LOADING_STOP]: getUserInfoLoadingStop,

  [AuthTypes.SEARCH_USER_SUCCESS]: searchUserSuccess,
  [AuthTypes.SEARCH_USER_FAILURE]: searchUserFailure,
  [AuthTypes.SEARCH_USER_LOADING]: searchUserLoading,
  [AuthTypes.SEARCH_USER_LOADING_STOP]: searchUserLoadingStop,
  [AuthTypes.CLEAR_SEARCH_RESULTS]: clearSearchResults,

  [AuthTypes.GET_CONTACTS_FROM_DEVICE_SUCCESS]: getContactsFromDeviceSuccess,
  [AuthTypes.GET_CONTACTS_FROM_DEVICE_FAILURE]: getContactsFromDeviceFailure,
  [AuthTypes.GET_CONTACTS_FROM_DEVICE_LOADING]: getContactsFromDeviceLoading,
  [AuthTypes.GET_CONTACTS_FROM_DEVICE_LOADING_STOP]:
    getContactsFromDeviceLoadingStop,

  [AuthTypes.GET_CONTACTS_SUCCESS]: getContactsSuccess,
  [AuthTypes.GET_CONTACTS_FAILURE]: getContactsFailure,
  [AuthTypes.GET_CONTACTS_LOADING]: getContactsLoading,
  [AuthTypes.GET_CONTACTS_LOADING_STOP]: getContactsLoadingStop,

  [AuthTypes.SYNC_CONTACTS_SUCCESS]: syncContactsSuccess,
  [AuthTypes.SYNC_CONTACTS_FAILURE]: syncContactsFailure,
  [AuthTypes.SYNC_CONTACTS_LOADING]: syncContactsLoading,
  [AuthTypes.SYNC_CONTACTS_LOADING_STOP]: syncContactsLoadingStop,

  [AuthTypes.SET_NEW_USER]: setNewUser,

  [AuthTypes.REGISTER_FCM_TOKEN_SUCCESS]: registerFcmTokenSuccess,
  [AuthTypes.REGISTER_FCM_TOKEN_FAILURE]: registerFcmTokenFailure,

  [AuthTypes.LAUNCH_SESSION_SUCCESS]: launchSessionSuccess,
  [AuthTypes.LAUNCH_SESSION_FAILURE]: launchSessionFailure,

  [AuthTypes.UPDATE_AUTHORIZATION_STATUS]: updateAuthorizationStatus,
  [AuthTypes.UPDATE_SIGN_IN_STATUS]: updateSignInStatus,
  [AuthTypes.UPDATE_IS_LOADING_TOKEN]: updateIsLoadingToken,

  //initiateLogin: ['payload'],
  [AuthTypes.INITIATE_LOGIN_SUCCESS]: initiateLoginSuccess,
  [AuthTypes.INITIATE_LOGIN_FAILURE]: initiateLoginFailure,

  //[AuthTypes.VERIFY_LOGIN]: verifyLogin,
  [AuthTypes.VERIFY_LOGIN_SUCCESS]: verifyLoginSuccess,
  [AuthTypes.STORE_GUEST_DATA_SUCCESS]: storeGuestDataSuccess,
  [AuthTypes.STORE_USER_DATA_SUCCESS]: storeUserDataSuccess,
  [AuthTypes.VERIFY_LOGIN_FAILURE]: verifyLoginFailure,

  //[AuthTypes.VERIFY_LOGIN_FAILURE]: setPin: ['payload'],
  [AuthTypes.SET_PIN_SUCCESS]: setPinSuccess,
  [AuthTypes.SET_PIN_FAILURE]: setPinFailure,

  //verifyPin: ['payload'],
  [AuthTypes.VERIFY_PIN_SUCCESS]: verifyPinSuccess,
  [AuthTypes.VERIFY_PIN_FAILURE]: verifyPinFailure,
  [AuthTypes.CLEAR_VERIFY_PIN_ERROR]: clearVerifyPinError,

  [AuthTypes.UPDATE_PIN_SUCCESS]: updatePinSuccess,
  [AuthTypes.UPDATE_PIN_FAILURE]: updatePinFailure,

  [AuthTypes.UPDATE_USER_SUCCESS]: updateUserSuccess,

  [AuthTypes.CHECK_PIN_EXISTS_SUCCESS]: checkPinExistsSuccess,
  [AuthTypes.CHECK_PIN_EXISTS_FAILURE]: checkPinExistsFailure,

  [AuthTypes.VERIFY_PRESENT_PIN_SUCCESS]: verifyPresentPinSuccess,
  [AuthTypes.VERIFY_PRESENT_PIN_FAILURE]: verifyPresentPinFailure,
  [AuthTypes.CLEAR_VERIFY_PRESENT_PIN_ERROR]: clearVerifyPresentPinError,

  [AuthTypes.CONFIRM_NEW_PIN_SUCCESS]: confirmNewPinSuccess,
  [AuthTypes.CONFIRM_NEW_PIN_FAILURE]: confirmNewPinFailure,

  [AuthTypes.CLEAR_VERIFY_PIN_SUCCESS]: clearVerifyPinSuccess,

  [AuthTypes.UPDATE_USER_KYC_STATUS]: updateUserKycStatus,

  [AuthTypes.UPDATE_NON_BALANCE_CONTACTS]: updateNonBalanceContacts,
  [AuthTypes.RESET_NON_BALANCE_CONTACT_SEARCH_RESULT]:
    resetNonBalanceContactSearchResult,
  [AuthTypes.UPDATE_PERMISSIONS_LIST]: updatePermissionsList,
  [AuthTypes.SET_APP_ENTERED_ONCE]: setAppEnteredOnce,
  [AuthTypes.FETCH_STORE_DATA_SUCCESS]: fetchStoreDataSuccess,

  [AuthTypes.FETCH_SMS_BOOKING_SUCCESS]: fetchSMSBookingSuccess,
  [AuthTypes.FETCH_SMS_BOOKING_LOADING]: fetchSMSBookingLoading,
  [AuthTypes.FETCH_SMS_BOOKING_FAILURE]: fetchSMSBookingFailure,

  [AuthTypes.CLOSE_BOOKING_EXPIRE_WARNING_MODAL]:
    CloseBookingExpireWarningModal,
  [AuthTypes.OPEN_BOOKING_EXPIRE_WARNING_MODAL]: OpenBookingExpireWarningModal,

  [AuthTypes.CLOSE_BOOKING_LOADING_MODAL]: CloseBookingLoadingModal,
  [AuthTypes.OPEN_BOOKING_LOADING_MODAL]: OpenBookingLoadingModal,
});
