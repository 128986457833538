import React from "react";
import PaymentSummary from "../payment/payment-summary";
import Styles from "src/containers/merchant-confirm-payment/styles.module.css";
const OrderTotal = ({ price, showConvienience }) => {
  const {
    billAmount,
    transactionNo,
    isRTAStore,
    // listPrice,
    // taxAmount,
    // discountedAmount,
    // convenienceAmount,
    // discountedPercentage,
    // conveniencePercentage,
  } = price;
  console.log(billAmount, "billAmount", price);
  return (
    <div className={Styles["order-total-container"]}>
      <div
        style={{
          fontFamily: "Light",
          fontStyle: "normal",
          fontWeight: 100,
          fontSize: "24px",
          lineHeight: "30px",
          display: "flex",
          justifyContent: "center",
          marginBottom: "20px",
          color: "white",
        }}
      >
        <div className={Styles["total-amount"]}>
          AED {(+billAmount / 100).toFixed(2)}
        </div>
      </div>
      <div className={Styles["separator"]}></div>
      <div
        className={Styles["total-title-container"]}
        style={{
          margin: "18px 0",
          justifyContent: "space-between",
          fontSize: "24px",
          color: "#D9D9D9",
        }}
      >
        <div>Activity no.</div>
        <div>{transactionNo}</div>
      </div>
      <div className={Styles["separator"]} style={{ marginTop: "0" }}></div>
      <div>
        {/* <div className={Styles["total-split-container"]}>
          <div className={Styles["total-parameter"]}> Processing fee (0%)</div>
          <div className={Styles["total-parameter"]}>AED 0.00</div>
        </div> */}

        <div className="total-split-container">
          <div className={Styles["total-parameter"]}>Ride fare amount</div>
          <div className={Styles["total-parameter"]}>
            AED {(billAmount / 100).toFixed(2)}
          </div>
        </div>
      </div>
      <div className={Styles["order-total-text"]}>
        Payments are not refundable
      </div>
      <div className={Styles["separator"]}></div>
    </div>
  );
};
export default OrderTotal;
