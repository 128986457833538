import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  startLoadingAction: ["action"],
  stopLoadingAction: ["action"],
  startRefreshingAction: ["action"],
  stopRefreshingAction: ["action"],
  stopLoadings: null,
});

export const UIReducerTypes = Types;
export default Creators;
