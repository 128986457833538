import React, { createRef } from "react";
import OrderTotal from "./order-total";
import PaymentSummary from "./payment-summary";
//import "./styles.css";
import { useEffect } from "react";
import { helperService } from "src/services/helperService";
import { ReactComponent as NetZeroQR } from "src/assets/images/NetZeroQR.svg";
import { ReactComponent as COP28 } from "src/assets/images/COP28.svg";
import { ReactComponent as RTADubai } from "src/assets/images/RTA_Dubai.svg";

import Styles from "./styles.module.css";
import axios from "axios";
import apiConstant from "src/config/env";
import { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const monthsOfYear = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const MerchantPaymentStatus = (props) => {
  const [paymentDetails, setPaymentDetails] = useState({});

  const RTATransaction = helperService.getItem("RTAtransactionID");
  const businessID = helperService.getItem("businessUserId");

  const isRTAStore = apiConstant.RTA_STORE_ID === paymentDetails?.store?.id;

  console.log(isRTAStore, "isRTAStore");

  useEffect(() => {
    axios
      .get(
        `${apiConstant.MERCHANT_BASE_URL}/business/transaction/user/${businessID}/reference/${RTATransaction}`
      )
      .then((r) => {
        console.log(r, "getpayment details");
        setPaymentDetails(r?.data?.data);
      })
      .catch((err) => {
        console.log(err, "getpaymentdetails");
      });
  }, [RTATransaction]);

  const { guestTransaction: price = {} } = paymentDetails || {};

  const {
    referenceId: id,
    createdAt,
    type,
    externalTransfer: {
      paymentMethod = "",
      externalReferenceId = "",
      amount = 0,
      paymentMethodMeta: {
        card: { scheme, last4 } = { scheme: "", last4: "" },
      } = {},
    } = {},
  } = paymentDetails?.transfer || {};

  const renderTitle = () => {
    return (
      <div
        style={{
          width: "90%",
          fontSize: "36px",
          margin: "20% auto 0",
          textAlign: "center",
          fontFamily: "Light",
          color: "#15AD31",
        }}
      >
        Payment Successful
      </div>
    );
  };

  const getDateString = (createdAt) => {
    const date = new Date(createdAt);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    let hour = date.getHours();
    let minute = date.getMinutes();
    const amPm = hour >= 12 ? "pm" : "am";

    // Convert hour to 12-hour format
    hour = hour % 12;
    hour = hour ? hour : 12;

    // Add leading zero to minutes if needed
    minute = minute < 10 ? "0" + minute : minute;

    return `${day} ${monthsOfYear[monthIndex]} ${year}, ${hour}:${minute} ${amPm}`;
  };

  const dateString = getDateString(createdAt);

  // if (Object.keys(paymentDetails).length == 0) {
  //   return (
  //     <div className="store-cat-container" style={{ paddingTop: "40%" }}>
  //       <ContentLoader viewBox="60 0 380 70" foregroundColor={"#999"}>
  //         {/* Only SVG shapes */}
  //         <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
  //         <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
  //       </ContentLoader>
  //       <ContentLoader viewBox="60 0 380 70" foregroundColor={"#999"}>
  //         {/* Only SVG shapes */}
  //         <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
  //         <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
  //       </ContentLoader>
  //       <ContentLoader viewBox="60 0 380 70" foregroundColor={"#999"}>
  //         {/* Only SVG shapes */}
  //         <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
  //         <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
  //       </ContentLoader>
  //     </div>
  //   );
  // }

  return (
    <div className={Styles["booking__status-container"]}>
      {isRTAStore ? (
        <Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            width={"90%"}
            margin={"auto"}
            marginTop={"15%"}
          >
            <RTADubai />
            <COP28 />
          </Box>
          <Box>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              marginTop="43px"
            >
              <NetZeroQR />
              <Typography fontSize={"32px"} color={"white"}>
                Net Zero
              </Typography>
            </Box>
            <Typography
              fontSize={"16px"}
              lineHeight={"36px"}
              color={"white"}
              textAlign={"center"}
            >
              We offset the carbon emissions for this ride
            </Typography>

            <div
              style={{
                width: "90%",
                fontSize: "36px",
                margin: "35px auto 0",
                textAlign: "center",
                fontFamily: "Light",
                color: "#15AD31",
              }}
            >
              Payment Successful
            </div>
          </Box>
        </Box>
      ) : (
        <div className={Styles["booking__status-inner-container"]}>
          {renderTitle()}
        </div>
      )}
      <OrderTotal
        price={{ ...price, billAmount: amount, transactionNo: "#" + id }}
        isRTAStore={isRTAStore}
      />

      <div
        style={{
          backgroundColor: "black",
          alignSelf: "center",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          paddingBottom: "40%",
        }}
      >
        <PaymentSummary
          dateString={dateString}
          cardName={paymentMethod === "CARD" ? scheme : paymentMethod}
          last4={last4}
          isRTAStore={isRTAStore}
          externalReferenceId={externalReferenceId}
        />
        <Box width="100%" marginY="40px" display={"flex"}>
          <Button
            variant="outlined"
            flexGrow={1}
            onClick={() => props.history.push("/merchant-details-form")}
            sx={{
              alignSelf: "center",
              margin: "auto",
              width: "90%",
              paddingTop: "16px",
              paddingBottom: "17px",
              borderRadius: "16px",
              border: "0.5px solid white",
              color: "white",
              textTransform: "none",
              "&:hover": {
                border: "0.5px solid white",
              },
            }}
          >
            Tap for Carbon Credit Certificate
          </Button>
        </Box>
      </div>
    </div>
  );
};

export default MerchantPaymentStatus;
