import React from "react";
import { ReactComponent as WrongStatus } from "src/assets/images/Wrong.svg";
import "./styles.css";

const ErrorMessage = ({ wrongPage = false, history }) => {
  return (
    <div
      className="error-container"
      style={{
        maxWidth: "500px",
      }}
    >
      <WrongStatus />
      <div className="error-heading-txt">No payment found</div>

      <div className="error-sub-heading-txt">
        Please ask the business to initiate a payment request to make a payment.
      </div>
    </div>
  );
};

export default ErrorMessage;
