import React from "react";
import { ReactComponent as ErrorStatus } from "src/assets/images/ErrorStatus.svg";
import { ReactComponent as WrongStatus } from "src/assets/images/Wrong.svg";
import { helperService } from "src/services/helperService";
import OrderTotal from "src/containers/merchant-confirm-payment/order-total";
import Styles from "src/containers/merchant-confirm-payment/styles.module.css";
const ErrorMessage = ({
  wrongPage = false,
  history,
  setFailureError,
  price,
  isRTAStore,
}) => {
  return (
    <div
      style={{
        backgroundColor: "black",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div className={Styles["category-heading"]} style={{ color: "#F13939" }}>
        Payment failed
      </div>
      <OrderTotal
        isRTAStore={isRTAStore}
        price={price}
        showConvienience={true}
        error={true}
      />
      <button
        className="nextBtn"
        style={{
          marginTop: "20%",
          height: "56px",
          backgroundColor: "#0095F6",
          textAlign: "center",
          borderRadius: "20px",
          paddingLeft: "40px",
          paddingRight: "40px",
          border: "none",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={() => {
          setFailureError();
          history.push({ pathname: "/payment" });
          window.location.reload();
        }}
      >
        {" "}
        <p className="nextBtnTxt" style={{ color: "white" }}>
          Try again
        </p>{" "}
      </button>
    </div>
  );
};

export default ErrorMessage;
