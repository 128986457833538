import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  getMerchantPaymentDetails: ["payload"],
  getMerchantPaymentDetailsLoading: null,
  getMerchantPaymentDetailsFailure: null,
  getMerchantPaymentDetailsSuccess: ["payload"],

  paymentAuthorization: ["payload"],
  paymentAuthorizationLoading: null,
  paymentAuthorizationFailure: null,
  paymentAuthorizationSuccess: ["payload"],

  paymentInitiate: ["payload"],
  paymentInitiateLoading: null,
  paymentInitiateFailure: null,
  paymentInitiateSuccess: ["payload"],

  paymentProcess: ["payload"],
  paymentProcessLoading: null,
  paymentProcessFailure: null,
  paymentProcessSuccess: ["payload"],

  getPaymentDetails: ["payload"],
  getPaymentDetailsLoading: null,
  getPaymentDetailsFailure: null,
  getPaymentDetailsSuccess: ["payload"],
});

export const MerchantTypes = Types;
export default Creators;
