import axios from "axios";
import { apiClientService } from "./apiClientService";
import { CHECKOUT_PUBLIC_KEY, helperService } from "src/services/helperService";
import apiConstants from "src/config/env";

const { apiClient, in200s } = apiClientService;

let base_url = apiConstants.BASE_URL;
let payment_url = apiConstants.MERCHANT_BASE_URL;
let checkout_url = apiConstants.CHECKOUT_URL;

const addCard = async (params) => {
  let url = checkout_url + "/tokens";
  return axios
    .post(url, params, {
      headers: { Authorization: CHECKOUT_PUBLIC_KEY },
    })
    .then((response) => {
      if (in200s(response.status)) {
        return response.data;
      }
      return null;
    })
    .catch((error) => {
      return null;
    });
  // let endpoint = CONFIG_ENV.CHECKOUT_API_URL + apiConstants.API_END_POINTS.CHECKOUT.ADD_CARD
  // const response = await postRequest(
  //   endpoint,
  //   param,
  //   {},
  //   { Authorization: CONFIG_ENV.CHECKOUT_PUBLIC_KEY },
  //   true
  // )
  // return GenericApiService.parseResponse(response)
};

const verifiedCardRequest = async (params, customHeaders) => {
  let url = payment_url + "/payment/initiate";

  return axios
    .post(url, params, {
      headers: {
        Authorization: helperService.getItem("partnerAccessToken"),
      },
    })
    .then((response) => {
      if (in200s(response.status)) {
        return response.data;
      }
      return null;
    })
    .catch((error) => {
      return null;
    });
  // let endpoint = apiConstants.API_END_POINTS.CHECKOUT.VERIFIED_REQUEST
  // const response = await postRequest(endpoint, params)
  // return GenericApiService.parseResponse(response)
};

const processCheckoutRequest = async (params, customHeaders) => {
  let url = payment_url + `/payment`;
  return axios
    .post(url, params, {
      headers: {
        Authorization: helperService.getItem("partnerAccessToken"),
        ...customHeaders,
      },
    })
    .then((response) => {
      if (in200s(response.status)) {
        return response.data;
      }
      return null;
    })
    .catch((error) => {
      return null;
    });
  // let endpoint = apiConstants.API_END_POINTS.CHECKOUT.VERIFIED_REQUEST
  // const response = await postRequest(endpoint, params)
  // return GenericApiService.parseResponse(response)
};

const getPartnerPaymentDetails = async (transactionNo) => {
  let url = payment_url + `/payment/${transactionNo}`;
  return axios
    .get(url)
    .then((response) => {
      if (in200s(response.status)) {
        return response.data;
      }
      return null;
    })
    .catch((error) => {
      return null;
    });
  // let endpoint = apiConstants.API_END_POINTS.CHECKOUT.VERIFIED_REQUEST
  // const response = await postRequest(endpoint, params)
  // return GenericApiService.parseResponse(response)
};

const getPayByBalanceDetails = async (params) => {
  let url =
    base_url +
    `/orm/payment/custom?invoiceId=${params.id}&amount=${params.amount}`;
  return apiClient
    .put(url)
    .then((response) => {
      if (in200s(response.status)) {
        return response.data;
      }
      return null;
    })
    .catch((error) => {
      return null;
    });
};

const recordTransferRequest = async (transactionID, params, customHeaders) => {
  let url = base_url + "/orm/user/login" + `/${transactionID}`;
  return apiClient
    .post(url, params, {
      headers: {
        Authorization: helperService.getAuthToken(),
        ...customHeaders,
      },
    })
    .then((response) => {
      if (in200s(response.status)) {
        return response.data;
      }
      return null;
    })
    .catch((error) => {
      return null;
    });
  // let endpoint = `${apiConstants.API_END_POINTS.CHECKOUT.RECORD_TRANSFER}/${transactionID}`
  // const response = await getRequest(endpoint)
  // return GenericApiService.parseResponse(response)
};

const getSavedCardList = async (params, customHeaders, walletID) => {
  let url = base_url + "/orm/user/login" + `/${walletID}`;
  return apiClient
    .get(url)
    .then((response) => {
      if (in200s(response.status)) {
        return response.data;
      }
      return null;
    })
    .catch((error) => {
      return null;
    });
  // let endpoint = `${apiConstants.API_END_POINTS.CHECKOUT.SAVED_CARDS}/${walletID}`
  // const response = await getRequest(endpoint)
  // return GenericApiService.parseResponse(response)
};

const paymentRequest = async (customHeaders, params) => {
  let url = base_url + "/orm/user/login";
  return apiClient
    .post(url, params, {
      headers: {
        Authorization: helperService.getAuthToken(),
        ...customHeaders,
      },
    })
    .then((response) => {
      if (in200s(response.status)) {
        return response.data;
      }
      return null;
    })
    .catch((error) => {
      return null;
    });
  // let endpoint = apiConstants.API_END_POINTS.CHECKOUT.PAYMENT
  // const response = await postRequest(endpoint, param)
  // return GenericApiService.parseResponse(response)
};

const getApplePayToken = async (params) => {
  let url = checkout_url + "/tokens";
  return axios
    .post(url, params, {
      headers: { Authorization: CHECKOUT_PUBLIC_KEY },
    })
    .then((response) => {
      if (in200s(response.status)) {
        return response.data;
      }
      return null;
    })
    .catch((error) => {
      return null;
    });
};

const partnerPaymentAuthorization = async ({
  clientId,
  clientType,
  clientChannel,
}) => {
  let url = payment_url + `/payment/auth`;
  const params = { clientId, clientType, clientChannel };
  return apiClient.post(url, params).then((response) => {
    console.log(response.data);
    helperService.setItem("partnerAccessToken", response.data.data.accessToken);
    return response.data;
  });
};

export const CheckoutService = {
  addCard,
  paymentRequest,
  getSavedCardList,
  verifiedCardRequest,
  processCheckoutRequest,
  recordTransferRequest,
  getApplePayToken,
  getPartnerPaymentDetails,
  getPayByBalanceDetails,
  partnerPaymentAuthorization,
};
