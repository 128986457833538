import axios from "axios";
import { apiClientService } from "./apiClientService";
import apiConstants from "src/config/env";
import { helperService } from "../helperService";

const { apiClient, in200s } = apiClientService;

let base_url = apiConstants.MERCHANT_BASE_URL;

const getMerchantDetails = async ({ businessUserId, paymentId }) => {
  let url = base_url + `/business/qr/${paymentId}/business/${businessUserId}`;

  return axios.get(url).then((response) => {
    console.log(response);
    helperService.setItem("getMerchantDeails", response.data.data);
    return response.data;
  });
};

const paymentAuthorization = async ({
  clientId,
  clientType,
  clientChannel,
}) => {
  let url = base_url + `/payment/auth`;
  const params = { clientId, clientType, clientChannel };
  return apiClient.post(url, params).then((response) => {
    console.log(response.data);
    helperService.setItem(
      "merchantAccessToken",
      response.data.data.accessToken
    );
    return response.data;
  });
};

const paymentInitiate = async ({ payload, authToken }) => {
  console.log(authToken, "authToken");
  let url = base_url + `/payment/initiate`;
  const params = payload;
  return axios
    .post(url, params, {
      headers: { Authorization: helperService.getItem("merchantAccessToken") },
    })
    .then((response) => {
      console.log(response.data);
      return response.data;
    });
};

const paymentProcess = async (payload) => {
  let url = base_url + `/payment`;
  const params = payload;
  return axios
    .post(url, params, {
      headers: { Authorization: helperService.getItem("merchantAccessToken") },
    })
    .then((response) => {
      console.log(response);
      return response.data;
    });
};

const getPaymentDetails = async (transactionNo) => {
  let url = base_url + `/payment/${transactionNo}`;
  console.log(
    helperService.getItem("merchantAccessToken"),
    "merchantAccessToken"
  );
  return axios
    .get(url, {
      headers: {
        Authorization: helperService.getItem("merchantAccessToken"),
      },
    })
    .then((response) => {
      console.log(response.data);
      return response.data;
    });
};

// {
//   "destination": {
//       "userId": "fd6e3c98-8daf-4256-bc47-d6256504daef",
//       "accountType": "MERCHANT"
//   },
//   "description": "b3d6517a-de2b-4c8e-9e74-86391dc6bc94",//QR Id
//   "invoice": {
//       "amount": 10000,
//       "fee": {
//           "processingFee": 0
//       },
//       "description": "Test"
//   },
//   "token": "sid_s2zctoazbcluvfao55sfbywtg4",
//   "transferType": "GUEST_PAYMENT",
//   "paymentChannel": "QR"
// }
export const MerchantService = {
  getMerchantDetails,
  paymentAuthorization,
  paymentInitiate,
  paymentProcess,
  getPaymentDetails,
};
