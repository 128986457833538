import { Box, Button, TextField, Typography } from "@mui/material";
import React from "react";
import { ReactComponent as NetZeroQR } from "src/assets/images/NetZeroQR.svg";
import { ReactComponent as COP28 } from "src/assets/images/COP28.svg";
import { ReactComponent as RTADubai } from "src/assets/images/RTA_Dubai.svg";
import { RxChevronDown } from "react-icons/rx";
import Loader from "src/components/loader";
import { Images } from "src/assets/images";
import * as Yup from "yup";
import { Formik } from "formik";
import axios from "axios";
import apiConstant from "src/config/env";
import { useState } from "react";
import { helperService } from "src/services/helperService";

const MerchantDetails = () => {
  const [submitApiLoading, setSubmitApiLoading] = React.useState(false);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    email: Yup.string().required().email(),
    phone: Yup.string().required(),
  });

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      backgroundColor="#000000"
      maxWidth={"500px"}
      minHeight={"fit-content"}
      sx={{
        fontFamily: "Light",
        height: "100%",
        paddingBottom: "10%",
        width: "100%",
      }}
    >
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
        }}
        onSubmit={async (values) => {
          try {
            setSubmitApiLoading(true);
            const RTATransaction = helperService.getItem("RTAtransactionID");
            const response = await axios.post(
              `${apiConstant.MERCHANT_BASE_URL}/user/guest/register`,
              {
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                phoneCountryCode: "971",
                phoneSubscriberNumber: values.phone,
                transactionReferenceNo: RTATransaction,
              }
            );
            const certUrl =
              response?.data?.data?.transfer
                ?.destinationAccountCarbonCreditTransactions
                ?.certificatePublicUrl;

            console.log(response, certUrl);

            window.open(certUrl, "_self");
          } catch (err) {
            console.log(err);
          } finally {
            setSubmitApiLoading(false);
          }
        }}
        ///yup
        validationSchema={validationSchema}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
          } = props;
          console.log(values, errors);
          return (
            <Box width={"100%"}>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                width={"90%"}
                margin={"auto"}
                marginTop={"15%"}
              >
                <RTADubai />
                <COP28 />
              </Box>
              <Box>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  marginTop="43px"
                >
                  <NetZeroQR />
                  <Typography fontSize={"32px"} color={"white"}>
                    Net Zero
                  </Typography>
                </Box>
                <Typography
                  fontSize={"16px"}
                  lineHeight={"36px"}
                  color={"white"}
                  textAlign={"center"}
                >
                  Enter your details to access your carbon certificate
                </Typography>

                <div
                  style={{
                    width: "90%",
                    fontSize: "36px",
                    margin: "35px auto 0",
                    textAlign: "center",
                    fontFamily: "Light",
                    color: "#15AD31",
                  }}
                >
                  Enter Details
                </div>
              </Box>
              <Box
                display="flex"
                flexDirection={"column"}
                gap="15px"
                sx={{
                  width: "90%",
                  margin: "auto",
                  marginTop: "37px",
                }}
              >
                <input
                  id="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="First Name"
                  type="text"
                  style={{
                    boxSizing: "border-box",
                    background: "black",
                    color: "white",
                    border:
                      !values.firstName && touched.firstName
                        ? "0.5px solid red"
                        : "0.5px solid white",
                    fontSize: "18px",
                    padding: "17px 24px",
                    borderRadius: "16px",
                    width: "100%",
                  }}
                />
                <input
                  id="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Last Name"
                  style={{
                    boxSizing: "border-box",
                    background: "black",
                    color: "white",
                    border:
                      !values.lastName && touched.lastName
                        ? "0.5px solid red"
                        : "0.5px solid white",
                    fontSize: "18px",
                    padding: "17px 24px",
                    borderRadius: "16px",
                    width: "100%",
                  }}
                />
                <input
                  id="email"
                  type="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Email"
                  style={{
                    boxSizing: "border-box",
                    background: "black",
                    color: "white",
                    border:
                      !values.email && touched.email
                        ? "0.5px solid red"
                        : "0.5px solid white",
                    fontSize: "18px",
                    padding: "17px 24px",
                    borderRadius: "16px",
                    width: "100%",
                  }}
                />
                <Box display={"flex"}>
                  <Box
                    display={"flex"}
                    flexGrow={1}
                    alignItems={"center"}
                    justifyContent={"center"}
                    sx={{
                      fontSize: "18px",
                      fontweight: 400,
                      color: "white",
                    }}
                  >
                    <img
                      src={Images.Flag}
                      alt="Logo"
                      style={{
                        width: "18px",
                        height: "12px",
                        marginRight: "8px",
                      }}
                    />
                    <p style={{ marginRight: "10px" }}>+971</p>
                    <RxChevronDown className="icon" />
                  </Box>
                  <input
                    id="phone"
                    pattern="\d*"
                    value={values.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Phone number"
                    style={{
                      boxSizing: "border-box",
                      background: "black",
                      color: "white",
                      border:
                        !values.phone && touched.phone
                          ? "0.5px solid red"
                          : "0.5px solid white",
                      fontSize: "18px",
                      padding: "17px 24px",
                      borderRadius: "16px",
                      width: "60%",
                    }}
                  />
                </Box>
              </Box>
              <Box width="100%" marginY="110px" display={"flex"}>
                <Button
                  variant="outlined"
                  flexGrow={1}
                  type="submit"
                  onClick={handleSubmit}
                  sx={{
                    alignSelf: "center",
                    margin: "auto",
                    width: "90%",
                    paddingTop: "16px",
                    paddingBottom: "17px",
                    borderRadius: "16px",
                    border: "0.5px solid white",
                    color: "white",
                    textTransform: "none",
                    "&:hover": {
                      border: "0.5px solid white",
                    },
                  }}
                >
                  {submitApiLoading ? <Loader /> : "Submit details"}
                </Button>
              </Box>
            </Box>
          );
        }}
      </Formik>
    </Box>
  );
};

export default MerchantDetails;
