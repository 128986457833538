import axios from "axios";
import React, { useState } from "react";
import { ReactComponent as AppleBlackIcon } from "src/assets/images/apple-black.svg";
import apiConstants from "src/config/env";
import Styles from "./styles.module.css";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import BookingLoaderModel from "./BookingLoaderModel/BookingLoaderModel";

const ApplePayButton = ({ verifyPayment, billAmount, setApplePaySession }) => {
  const [bookingLoaderModel, setBookingLoaderModel] = useState(false);
  const [initiateLoading, setInitiateLoading] = useState(false);

  const data = useSelector(
    (state) => state.merchant?.getMerchantPaymentDetailsSuccess
  );

  // Handle initialization of Apple Pay session
  // console.log(data, {
  //   OrderID: data?.merchantRequestDTO?.id,
  //   OrderName: data?.qrdto?.store.brand?.name,
  //   OrderInfo: data?.qrdto?.store?.name,
  // });
  const [registerCheckoutRes, setRegisterCheckoutRes] = useState({});

  const handleApplePay = async () => {
    try {
      setBookingLoaderModel(true);
      const session = new window.ApplePaySession(6, {
        countryCode: "SA",
        currencyCode: "AED",
        supportedNetworks: ["visa", "masterCard", "amex", "discover"],
        merchantCapabilities: ["supports3DS"],
        total: {
          label: "Bill amount",
          amount: billAmount,
        },
      });
      session.begin();

      session.onvalidatemerchant = async (event) => {
        // Perform server-side validation of merchant
        // and pass back merchant session object
        console.log("onvalidatemerchant", event);
        // let appleUrl = 'https://apple-pay-gateway.apple.com/paymentservices/paymentSession' //event.validationURL
        const validationURL = `${apiConstants.BASE_URL}/apple/validate?appleurl=${event.validationURL}&merchantDomain=${window.location.host}`;
        await axios
          .post(validationURL, {})
          .then((response) => {
            console.log("onvalidatemerchant response", response);
            return response?.data;
          })
          .then(async (merchantSession) => {
            console.log("onvalidatemerchant merchantSession", merchantSession);

            const createSessionRes = await axios.post(
              `${apiConstants.MERCHANT_BASE_URL}/etisalat/payment/applepay/create/session`,
              {
                TransactionID: registerCheckoutRes.Transaction.TransactionID,
                SessionID: registerCheckoutRes.Transaction.SessionID,
              }
            );

            session.completeMerchantValidation(merchantSession);
          })
          .catch((err) => {
            console.log(err);

            setBookingLoaderModel(false);
          });
      };

      session.onpaymentauthorized = (event) => {
        // Perform server-side processing of payment
        console.log("onvalidatemerchant merchantSession", event.payment.token);

        const paymentToken = event.payment.token;

        const payload = {
          WalletResponseJSON: JSON.stringify(paymentToken),
          TransactionID: registerCheckoutRes.Transaction.TransactionID,
          SessionID: registerCheckoutRes.Transaction.SessionID,
        };

        verifyPayment(payload, (outcome) => {
          console.log(
            outcome,
            "callback",
            window.ApplePaySession.STATUS_SUCCESS
          );
          setBookingLoaderModel(false);
          if (outcome) {
            session.completePayment(window.ApplePaySession.STATUS_SUCCESS);
          } else {
            session.completePayment(window.ApplePaySession.STATUS_FAILURE);
          }
        });
      };

      setApplePaySession(session);
    } catch (error) {
      console.error("Error initializing Apple Pay session", error);
    }
  };

  useEffect(() => {
    if (billAmount) {
      setInitiateLoading(true);
      axios
        .post(
          `${apiConstants.MERCHANT_BASE_URL}/etisalat/payment/applepay/initiate`,
          {
            OrderID: data?.qrdto?.store?.balanceKey,
            OrderName: data?.qrdto?.store.brand?.name,
            OrderInfo: data?.merchantRequestDTO?.id,
            Channel: "Web",
            Amount: billAmount,
            Currency: "AED",
            TransactionHint: "CPT:N",
            WalletName: "Apple Pay",
          }
        )
        .then((r) => {
          console.log(r, "registerCheckout");
          setRegisterCheckoutRes(r?.data?.data);
        })
        .catch((err) => console.log(err, "registerCheckout"))
        .finally((e) => {
          setInitiateLoading(false);
        });
    }
  }, [billAmount]);

  if (!window.ApplePaySession || initiateLoading) {
    return null;
  }

  return (
    <div
      className={Styles["card-pay-button-container"]}
      style={{ width: "100%" }}
    >
      <BookingLoaderModel isLoading={bookingLoaderModel} />
      <button
        className={`${Styles["card-pay-button"]} ${Styles[`active-button`]}`}
        onClick={handleApplePay}
      >
        <AppleBlackIcon />{" "}
        <div style={{ marginTop: "4px", marginLeft: "4px", color: "black" }}>
          {" Pay"}
        </div>
      </button>
    </div>
  );
};

export default ApplePayButton;
