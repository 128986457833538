import { Redirect, Route, Switch } from "react-router";
import { helperService } from "src/services/helperService";
import BookingReferenceInfo from "./containers/booking-reference-info";
import MerchantPaymentStatus from "./containers/merchant-payment-status";
import MerchantConfirmPayment from "./containers/merchant-confirm-payment";
import BalanceProcessing from "./containers/balance-processing";
import MerchantDetailsForm from "./containers/merchant-details-form";
import BalancePayDetails from "./containers/balance-pay-details";

// import NotFound from "src/containers/not_found";
// import Profile from "src/containers/profile";
// import OTPScreen from "src/containers/otp";
// import Login from "src/containers/login";
// import StoreCategory from "src/containers/store_category";
// import StoreSession from "src/containers/store_session";
// import StoreBooking from "src/containers/booking";
// import PaymentStatus from "src/containers/payment/payment-status";
// import BookingSlots from "src/containers/booking-slot";
// import BookingStatus from "src/containers/booking-status";
// import ConfirmPayment from "src/containers/confirm-payment";
// import payByBalanceSuccess from "./containers/pay-by-balance-success";

const AllRoutes = () => {
  const loggedIn = !!helperService.loggedIn();
  // const routes = [
  //   {
  //     path: "/session",
  //     component: StoreSession,
  //   },
  //   { path: "/categories", component: StoreCategory },
  //   { path: "/booking", component: StoreBooking },
  //   { path: "/payment-status", component: PaymentStatus },
  //   { path: "/confirm-payment", component: ConfirmPayment },
  //   { path: "/booking-slots", component: BookingSlots },
  //   { path: "/booking-status", component: BookingStatus },
  //   { path: "/transaction/success", component: ConfirmPayment },
  //   { path: "/transaction/failure", component: ConfirmPayment },
  //   { path: "/pay-by-balance-booking", component: payByBalanceSuccess },
  //   { path: "/balance-processing", component: BalanceProcessing },
  // ];

  const renderRoutes = () => {
    if (!loggedIn) {
      return (
        <Switch>
          <Route path="/balance-processing" component={BalanceProcessing} />
          <Route
            path="/merchant-payment-status"
            component={MerchantPaymentStatus}
          />
          <Route
            path="/merchant-details-form"
            component={MerchantDetailsForm}
          />
          <Route path="/balance-pay-details" component={BalancePayDetails} />
          <Route path="/payment" component={MerchantConfirmPayment} />
          <Route path="/payment/success" component={MerchantConfirmPayment} />
          <Route path="/payment/failure" component={MerchantConfirmPayment} />
          <Route path="/:invoiceId" component={BookingReferenceInfo} />
          <Route path="/*" component={MerchantConfirmPayment} />
        </Switch>
      );
    } else {
      return (
        <Switch>
          {/* {routes.map((route, index) => (
            <Route key={index} path={route.path} component={route.component} />
          ))} */}
          {/* <Route
            path="/merchant-payment-status"
            component={MerchantPaymentStatus}
          />
          <Route path="/payment" component={MerchantConfirmPayment} />
          <Route path="/payment/success" component={MerchantConfirmPayment} />
          <Route path="/payment/failure" component={MerchantConfirmPayment} />
          <Route path="/profile" component={Profile} />
          <Route path="/:invoiceId" component={BookingReferenceInfo} />
          {/*User already logged so redirect to home page*/}
          {/* <Route path="/login" render={() => <Redirect to="/" />} />  */}
          {/* <Route
            exact
            path="/"
            render={() => <Redirect to={"/categories"} />}
          />
          <Route component={NotFound} /> */}
        </Switch>
      );
    }
  };

  return renderRoutes();
};

export default AllRoutes;
