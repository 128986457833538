import React from "react";
import Styles from "./styles.module.css";
/* eslint-disable no-undef */
import apiConstant from "src/config/env";
import { useState } from "react";
import ApplePayButton from "src/containers/merchant-confirm-payment/ApplePayButton";
import axios from "axios";
import { useSelector } from "react-redux";
import { ReactComponent as AmexLogo } from "src/assets/images/amex.svg";
import { ReactComponent as MasterCardLogo } from "src/assets/images/mastercard.svg";
import { ReactComponent as VisaLogo } from "src/assets/images/visa.svg";
import { ReactComponent as MaestroLogo } from "src/assets/images/maestro.svg";
import Loader from "src/components/loader";
import { usePaymentInputs } from "react-payment-inputs";
import { Box, Typography } from "@mui/material";
import { helperService } from "src/services/helperService";
import { useEffect } from "react";

const PaymentOptions = ({ history, price, setFailureError, isRTAStore }) => {
  const [applePaySession, setApplePaySession] = useState(null);
  const [callAuthCardApiLoading, setcallAuthCardApiLoading] = useState(false);

  const [cardDetails, setCardDetails] = useState({
    cardNumber: "",
    cvv: "",
    exp: "",
  });

  const {
    meta,
    wrapperProps,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
  } = usePaymentInputs();

  const data = useSelector(
    (state) => state.merchant?.getMerchantPaymentDetailsSuccess
  );

  // console.log(meta, "wrapperProps", wrapperProps);

  const appleProcessPayment = (transactionID, successCallBack) => {
    console.log(
      "appleProcessPayment",
      typeof successCallBack === "function",
      successCallBack
    );

    axios
      .post(
        `${apiConstant.MERCHANT_BASE_URL}/etisalat/payment/applepay/finalize`,
        {
          Finalization: {
            TransactionID: transactionID,
          },
          Destination: {
            userId: data?.qrdto?.businessUser?.id,
            accountType: "MERCHANT",
          },
          Description: helperService.getItem("paymentId"), //QR Id
          Invoice: {
            amount: price?.billAmount,
            fee: {
              merchantCarbonContribution: price?.merchantCarbonContribution,
              customerCarbonContribution: price?.customerCarbonContribution,
            },
            description: helperService.getItem("paymentId"),
          },
          TransferType: isRTAStore ? "RTA" : "GUEST_PAYMENT",
          PaymentChannel: "QR",
        }
      )
      .then((response) => {
        console.log(response, "finalize");
        successCallBack(true);

        if (response?.data?.data?.message !== "SUCCESS") {
          throw new Error("some thing went wrong");
        } else {
          history.push("/merchant-payment-status");
          helperService.setItem(
            "RTAtransactionID",
            response?.data?.data?.transactionNo
          );
        }
      })
      .catch((err) => console.log(err, "finalize"));
  };

  const applePayVerifyPayment = async (payload, callBack) => {
    console.log(payload);
    axios
      .post(
        `${apiConstant.MERCHANT_BASE_URL}/etisalat/payment/applepay/submit/payload`,
        payload
      )
      .then((r) => {
        console.log(r, "submit.payload");
        appleProcessPayment(payload.TransactionID, callBack);
      })
      .catch((err) => console.log(err));
  };

  const callAuthCardApi = async () => {
    try {
      setcallAuthCardApiLoading(true);
      const response = await axios.post(
        `${apiConstant.MERCHANT_BASE_URL}/etisalat/payment/authorize`,
        {
          Authorization: {
            Currency: "AED",
            Channel: "E",
            Amount: isRTAStore
              ? (price?.billAmount + price?.customerCarbonContribution) / 100
              : price?.billAmount / 100,
            TransactionHint: "CPT:Y;SCN:E", //Hardcoded
            OrderName: data?.merchantRequestDTO?.createdBy?.loginId,
            OrderID: data?.qrdto?.store?.balanceKey, //merchantRequestDTO.store.balanceKey
            CardNumber: cardDetails?.cardNumber,
            ExpiryMonth: cardDetails?.exp?.split("/")?.[0],
            ExpiryYear: "20" + cardDetails?.exp?.split("/")?.[1],
            VerifyCode: cardDetails?.cvv, //CVV
          },
          Destination: {
            userId: data?.qrdto?.businessUser?.id,
            accountType: "MERCHANT",
          },
          Description: helperService.getItem("paymentId"), //QR Id
          Invoice: {
            amount: price?.billAmount,
            fee: {
              merchantCarbonContribution: price?.merchantCarbonContribution,
              customerCarbonContribution: price?.customerCarbonContribution,
            },
            description: helperService.getItem("paymentId"),
          },
          TransferType: isRTAStore ? "RTA" : "GUEST_PAYMENT",
          PaymentChannel: "QR",
        }
      );
      setcallAuthCardApiLoading(false);
      console.log(response);
      if (response?.data?.data?.message !== "SUCCESS") {
        throw new Error("some thing went wrong");
      } else {
        history.push("/merchant-payment-status");
        helperService.setItem(
          "RTAtransactionID",
          response?.data?.data?.transactionNo
        );
      }
    } catch (err) {
      setcallAuthCardApiLoading(false);
      setFailureError(true);
      console.log(err, "card error response");
    }
  };

  const renderCardDetails = () => {
    return (
      <div
        className="card-details-container"
        style={{ padding: "18px 18px", width: "90%" }}
      >
        <div className="card-image-container">
          <VisaLogo style={{ marginRight: 20 }} />
          <MasterCardLogo style={{ marginRight: 20 }} />
          <MaestroLogo style={{ marginRight: 20 }} />
          <AmexLogo style={{ marginRight: 20 }} />
        </div>

        <input
          id="cardNumber"
          style={{
            background: "black",
            color: "white",
            border:
              meta.erroredInputs.cardNumber && meta.touchedInputs.cardNumber
                ? "0.5px solid red"
                : "0.5px solid white",
            fontSize: "16px",
            padding: "7px 8px",
            borderRadius: "12px",
            margin: "18px 0",
          }}
          placeholder="Card Number"
          {...getCardNumberProps({
            onChange: (e) =>
              setCardDetails({
                ...cardDetails,
                cardNumber: e.target.value?.split(" ")?.join(""),
              }),
          })}
        />
        <input
          style={{
            background: "black",
            color: "white",
            border: "1px solid white",
            fontSize: "16px",
            padding: "7px 8px",
            borderRadius: "12px",
          }}
          placeholder="Name on card"
        />
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          sx={{ margin: "18px 0" }}
        >
          <Box display={"flex"} alignItems={"center"}>
            <Typography
              sx={{ fontSize: "16px", color: "#848C98", marginRight: "10px" }}
            >
              Exp
            </Typography>
            <input
              {...getExpiryDateProps({
                onChange: (e) =>
                  setCardDetails({
                    ...cardDetails,
                    exp: e.target.value?.split(" ")?.join(""),
                  }),
              })}
              style={{
                background: "black",
                color: "white",
                border:
                  meta.erroredInputs.expiryDate && meta.touchedInputs.expiryDate
                    ? "0.5px solid red"
                    : "0.5px solid white",
                fontSize: "16px",
                padding: "7px 8px",
                borderRadius: "12px",
                width: "25%",
              }}
            />
          </Box>
          <input
            {...getCVCProps({
              ...cardDetails,
              onChange: (e) =>
                setCardDetails({ ...cardDetails, cvv: e.target.value }),
            })}
            placeholder="CVV"
            style={{
              background: "black",
              color: "white",
              border:
                meta.erroredInputs.cvc && meta.touchedInputs.cvc
                  ? "0.5px solid red"
                  : "0.5px solid white",
              fontSize: "16px",
              padding: "7px 8px",
              borderRadius: "12px",
              width: "15%",
            }}
          />
        </Box>
        {!meta.erroredInputs.cardNumber &&
          !meta.erroredInputs.cvc &&
          !meta.erroredInputs.expiryDate && (
            <div className="">
              <button
                id="pay__button"
                className={
                  `card-pay-button active-button` +
                  (callAuthCardApiLoading ? " touched" : "")
                }
                onClick={() => callAuthCardApi()}
                onMouseDown={() =>
                  document
                    .getElementById("pay__button")
                    .classList.add("touched")
                }
                disabled={callAuthCardApiLoading}
              >
                <div className="card-pay-text">
                  {callAuthCardApiLoading ? <Loader /> : "Pay"}
                </div>
              </button>
            </div>
          )}
      </div>
    );
  };

  useEffect(() => {
    console.log(PaymentModule, "PaymentModule.........");
    if (!PaymentModule) return;
    PaymentModule?.initialise({
      clientId: helperService.getItem("businessUserId"),
      clientChannel: "QR",
      clientType: "BUSINESS",
      data: {
        amount: price.billAmount,
        description: "Test payment",
        referenceId: "123456789",
        transferType: "PAY_BY_BALANCE",
      },
      successCallback: function (response) {
        console.log(response);
        history.push("/balance-pay-details");
      },
      failureCallback: function (response) {
        console.log(response);
      },
      onRequestInitiated: function (response) {
        console.log(response);
        const requestId = response?.data?.requestId;
        helperService.setItem("requestId", requestId);
      },
    });
  }, []);

  return (
    <div className={Styles["payment-options-container"]}>
      <div className={Styles["page-title-text"]}>Payment options</div>
      <ApplePayButton
        verifyPayment={applePayVerifyPayment}
        billAmount={price.billAmount / 100}
        setApplePaySession={setApplePaySession}
      />

      {renderCardDetails()}
      <div id="payment-container" />
    </div>
  );
};

export default PaymentOptions;
