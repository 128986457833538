import _ from "lodash";
import apiConstants from "src/config/env";

let GLOBAL_CONSTANTS = {};

if (typeof window != "undefined") {
  var storage = window.localStorage;
}

function setAuthToken(value) {
  GLOBAL_CONSTANTS.AUTH_TOKEN = value;
  try {
    setItem("authToken", value);
    return true;
  } catch (e) {
    return false;
  }
}

function removeAuthToken() {
  GLOBAL_CONSTANTS.AUTH_TOKEN = "";
  try {
    setItem("authToken", "");
    return true;
  } catch (e) {
    return false;
  }
}

function getAuthToken() {
  try {
    const value = storage.getItem("authToken");
    GLOBAL_CONSTANTS.AUTH_TOKEN = value;
    return value;
  } catch (e) {
    return null;
  }
}

function dateReadableFormat(timestamp) {
  if (!timestamp) return "";
  let dateObj = new Date(timestamp);
  let date = dateObj.getUTCDate();
  let month = dateObj.getUTCMonth() + 1;
  let year = dateObj.getUTCFullYear();
  date = date < 10 ? "0" + date : date;
  month = month < 10 ? "0" + month : month;
  return `${date}/${month}/${year} (UTC)`;
}

function setItem(key, value) {
  try {
    storage.setItem(key, JSON.stringify(value));
  } catch (error) {
    return "";
  }
}

function getItem(key) {
  try {
    return JSON.parse(storage.getItem(key));
  } catch (error) {
    return "";
  }
}

function clearCookie() {
  setItem("cookie", "");
  setItem("refreshToken", "");
}

function convertToSearchableListFormat(params) {
  let list = params.list;
  let id_key = params.id_key;
  let label_key = params.label_key;

  if (id_key && label_key) {
    list = list.map((obj) => {
      return {
        value: obj[id_key],
        text: replaceSpecialChars(obj[label_key], ""),
      };
    });
  } else {
    list = list.map((obj) => {
      return {
        value: obj,
        text: replaceSpecialChars(obj, ""),
      };
    });
  }

  return list;
}

function findMatchingKeyValueInList({
  list,
  matchingKey,
  matchingValue,
  matchingValueKey,
}) {
  let result = [];
  result = list.filter((obj) => obj[matchingKey] == matchingValue);
  if (result && result[0]) {
    return result[0][matchingValueKey];
  }
  return "";
}

function findMatchingObjectList({ list, matchingKey, matchingValue }) {
  let result = [];
  result = list.filter((obj) => obj[matchingKey] == matchingValue);
  if (result && result[0]) {
    return result[0];
  }
  return "";
}

function navigate(route) {
  // import('../../stores').then(Module => {
  // 	Module.history.push(route)
  // });
  window.location.href = route;
}

function replaceSpecialChars(str, removeStr) {
  return str ? str.replace(/[^a-zA-Z0-9 ]/g, removeStr) : "";
}

function convertToActualCurrency(amount) {
  if (!amount) {
    return "0.00";
  }
  return (amount / 100).toFixed(2);
}

function loggedIn() {
  return getItem("authToken");
}

function daysDifference(end_date, start_date) {
  let end = new Date(end_date);
  let start = new Date(start_date);
  var Difference_In_Time = end.getTime() - start.getTime();
  var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
  return Difference_In_Days;
}

function searchTextListFilter(list, field, searchText, field2) {
  let text = searchText.toLowerCase();
  if (!text || text === "") {
    return list;
  }

  let filteredList = [];

  if (field2) {
    filteredList = list.filter((item) => {
      if (item[field] && item[field][field2]) {
        return item[field][field2].toLowerCase().match(text);
      } else {
        return false;
      }
    });
  } else {
    filteredList = list.filter((item) => {
      if (item[field]) {
        return item[field].toLowerCase().match(text);
      } else {
        return false;
      }
    });
  }

  if (!Array.isArray(filteredList) && !filteredList.length) {
    return [];
  }

  return filteredList;
}

function getDaysInMonth(year, month) {
  return new Date(year, month, 0).getDate();
}

function calculateTime(duration) {
  // Hours, minutes and seconds
  var hrs = ~~(duration / 3600);
  var mins = ~~((duration % 3600) / 60);
  var secs = ~~duration % 60;

  // Output like "1:01" or "4:03:59" or "123:03:59"
  var ret = "";

  if (hrs > 0) {
    ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
  }

  ret += "" + mins + ":" + (secs < 10 ? "0" : "");
  ret += "" + secs;
  return ret;
}

function getClosingBalanceFromLedgers(walletId, transfer) {
  if (!walletId || !transfer || !transfer.ledgers) {
    return "Not Applicable";
  }

  const ledgers = transfer.ledgers;

  const walletLedger = ledgers.find((l) => l.walletId === walletId);
  if (!walletLedger) {
    return "Not Applicable";
  }

  return convertToActualCurrency(walletLedger["closingBalance"]);
}

export const CHECKOUT_MESSAGE_STATUS = {
  CARD_VERIFIED: "CARD_VERIFIED",
  PAYMENT_SUCCESS: "PAYMENT_SUCCESS",
  USER_INPUT_REQUIRED: "USER_INPUT_REQUIRED",
};

export const CHECKOUT_REDIRECT_URL = {
  CHECKOUT_SUCCESS_URL: apiConstants.CHECKOUT_SUCCESS_URL,
  CHECKOUT_FAILURE_URL: apiConstants.CHECKOUT_FAILURE_URL,
};

export const CHECKOUT_PUBLIC_KEY = apiConstants.CHECKOUT_PUBLIC_KEY;

export const helperService = {
  convertToSearchableListFormat,
  findMatchingKeyValueInList,
  findMatchingObjectList,
  dateReadableFormat,
  getItem,
  setItem,
  clearCookie,
  navigate,
  replaceSpecialChars,
  loggedIn,
  daysDifference,
  searchTextListFilter,
  convertToActualCurrency,
  calculateTime,
  getClosingBalanceFromLedgers,
  setAuthToken,
  removeAuthToken,
  getAuthToken,
  getDaysInMonth,
};
