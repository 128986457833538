import { INITIAL_STATE } from "./initialState";
import { createReducer } from "reduxsauce";
import { CheckoutTypes } from "./actions";

export const saveCards = (state, { payload }) => {
  return {
    ...state,
    cards: payload.data.cards,
    monthlyLimit: payload.data.monthlyLimit
      ? Number(payload.data.monthlyLimit) / 100
      : state.monthlyLimit,
    currentMonthUsage: payload.data.currentMonthUsage
      ? Number(payload.data.currentMonthUsage) / 100
      : state.currentMonthUsage,
  };
};

export const saveAddCardResponse = (state, { payload }) => {
  return {
    ...state,
    addCardResponse: payload,
  };
};

export const verifyPaymentResponse = (state, { payload }) => {
  return {
    ...state,
    verifyPaymentResponse: payload,
  };
};

export const loadingStart = (state) => ({
  ...state,
  loader: true,
});

export const loadingStop = (state) => ({
  ...state,
  loader: false,
});

export const updateCurrentMonthUsage = (state, { payload }) => {
  return {
    ...state,
    currentMonthUsage: Number(payload) / 100,
  };
};
export const verifyPayByBalance = (state, { payload }) => {
  return {
    ...state,
    verifyPayByBalanceDetails: payload,
  };
};

///////

// authorization
export const partnerPaymentAuthorizationLoading = (state, { payload }) => {
  return {
    ...state,
    partnerPaymentAuthorizationLoading: true,
    partnerPaymentAuthorizationError: false,
  };
};

export const partnerPaymentAuthorizationFailure = (state, { payload }) => {
  return {
    ...state,
    partnerPaymentAuthorizationLoading: false,
    partnerPaymentAuthorizationError: true,
  };
};

export const partnerPaymentAuthorizationSuccess = (state, { payload }) => {
  return {
    ...state,
    partnerPaymentAuthorizationSuccess: payload,
    partnerPaymentAuthorizationLoading: false,
    partnerPaymentAuthorizationError: false,
  };
};

// payment details
export const getPartnerPaymentDetailsLoading = (state, { payload }) => {
  return {
    ...state,
    getPartnerPaymentDetailsLoading: true,
    getPartnerPaymentDetailsFailure: false,
  };
};

export const getPartnerPaymentDetailsFailure = (state, { payload }) => {
  return {
    ...state,
    getPartnerPaymentDetailsLoading: false,
    getPartnerPaymentDetailsFailure: true,
  };
};

export const getPartnerPaymentDetailsSuccess = (state, { payload }) => {
  return {
    ...state,
    getPartnerPaymentDetailsSuccess: payload,
    getPartnerPaymentDetailsLoading: false,
    getPartnerPaymentDetailsFailure: false,
  };
};

export const reducer = createReducer(INITIAL_STATE, {
  [CheckoutTypes.LOADING_START]: loadingStart,
  [CheckoutTypes.LOADING_STOP]: loadingStop,
  [CheckoutTypes.SAVE_CARDS]: saveCards,
  [CheckoutTypes.SAVE_ADD_CARD_RESPONSE]: saveAddCardResponse,
  [CheckoutTypes.VERIFY_PAYMENT_RESPONSE]: verifyPaymentResponse,
  [CheckoutTypes.UPDATE_CURRENT_MONTH_USAGE]: updateCurrentMonthUsage,
  [CheckoutTypes.VERIFY_PAY_BY_BALANCE]: verifyPayByBalance,

  [CheckoutTypes.PARTNER_PAYMENT_AUTHORIZATION_LOADING]:
    partnerPaymentAuthorizationLoading,
  [CheckoutTypes.PARTNER_PAYMENT_AUTHORIZATION_FAILURE]:
    partnerPaymentAuthorizationFailure,
  [CheckoutTypes.PARTNER_PAYMENT_AUTHORIZATION_SUCCESS]:
    partnerPaymentAuthorizationSuccess,

  [CheckoutTypes.GET_PARTNER_PAYMENT_DETAILS_LOADING]:
    getPartnerPaymentDetailsLoading,
  [CheckoutTypes.GET_PARTNER_PAYMENT_DETAILS_FAILURE]:
    getPartnerPaymentDetailsFailure,
  [CheckoutTypes.GET_PARTNER_PAYMENT_DETAILS_SUCCESS]:
    getPartnerPaymentDetailsSuccess,
});
