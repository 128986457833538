import { all, takeEvery, takeLatest } from "redux-saga/effects";

import {
  initiateLogin,
  verifyLogin,
  updateUser,
  resendOtp,
  fetchStoreData,
  fetchSMSBooking,
} from "./AuthSaga";
import { AuthTypes } from "src/stores/auth/actions";
import CheckoutSaga from "./Checkout";
import MerchantSaga from "./Merchant";

export default function* root() {
  yield all([
    takeEvery(AuthTypes.INITIATE_LOGIN, initiateLogin),
    takeLatest(AuthTypes.VERIFY_LOGIN, verifyLogin),
    takeLatest(AuthTypes.UPDATE_USER, updateUser),
    takeLatest(AuthTypes.RESEND_OTP, resendOtp),
    takeLatest(AuthTypes.FETCH_STORE_DATA, fetchStoreData),
    takeLatest(AuthTypes.FETCH_SMS_BOOKING, fetchSMSBooking),
    CheckoutSaga(),
    MerchantSaga(),
  ]);
}
