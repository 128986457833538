import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  generateOtpSuccess: ["payload"],
  generateOtpFailure: null,
  generateOtpRequest: ["payload"],
  generateOtpLoading: null,
  generateOtpLoadingStop: null,

  loginSuccess: ["payload"],
  loginFailure: null,
  loginLoading: null,
  loginLoadingStop: null,

  updateUser: ["payload"],
  updateUserInfoSuccess: ["payload"],
  updateUserInfoFailure: null,
  updateUserInfoRequest: ["payload"],
  updateUserInfoLoading: null,
  updateUserInfoLoadingStop: null,

  getUserInfoSuccess: ["payload"],
  getUserInfoFailure: null,
  getUserInfoRequest: ["payload"],
  getUserInfoLoading: null,
  getUserInfoLoadingStop: null,

  searchUser: ["payload"],
  searchNonBalanceUser: ["payload"],
  searchUserSuccess: ["payload"],
  searchUserFailure: null,
  searchUserLoading: null,
  searchUserLoadingStop: null,
  clearSearchResults: null,

  getContactsFromDevice: null,
  getContactsFromDeviceSuccess: ["payload"],
  getContactsFromDeviceFailure: null,
  getContactsFromDeviceLoading: null,
  getContactsFromDeviceLoadingStop: null,

  getContacts: ["payload"],
  getContactsSuccess: ["payload"],
  getContactsFailure: null,
  getContactsLoading: null,
  getContactsLoadingStop: null,

  syncContacts: null,
  syncContactsSuccess: ["payload"],
  syncContactsFailure: null,
  syncContactsLoading: null,
  syncContactsLoadingStop: null,

  handleHomeScreenSearchClick: null,
  handleAllowAccessClick: ["payload"],
  handleHomeScreenInviteClick: null,
  handleHomeScreenCampaignInviteClick: null,

  logout: null,
  logoutSuccess: null,

  logoutUser: null,
  logoutUserSuccess: null,

  feedBackRequest: ["payload"],

  closeAccount: ["payload"],

  setNewUser: ["payload"],

  registerFcmToken: ["payload"],
  registerFcmTokenSuccess: ["payload"],
  registerFcmTokenFailure: null,

  launchSession: null,
  launchSessionSuccess: ["payload"],
  launchSessionFailure: null,

  updateAuthorizationStatus: ["payload"],
  updateSignInStatus: ["payload"],
  updateIsLoadingToken: ["payload"],

  initiateLogin: ["payload"],
  initiateLoginSuccess: ["payload"],
  initiateLoginFailure: null,

  verifyLogin: ["payload"],
  verifyLoginSuccess: ["payload"],
  verifyLoginFailure: null,

  setPin: ["payload"],
  setPinSuccess: ["payload"],
  setPinFailure: null,

  verifyPin: ["payload"],
  verifyPinSuccess: ["payload"],
  storeGuestDataSuccess: ["payload"],
  storeUserDataSuccess: ["payload"],
  verifyPinFailure: ["payload"],
  clearVerifyPinError: null,
  clearVerifyPinSuccess: null,

  updatePin: ["payload"],
  updatePinSuccess: ["payload"],
  updatePinFailure: null,

  resendOtp: null,
  resendOtpSuccess: ["payload"],
  resendOtpFailure: null,

  checkPinExists: null,
  checkPinExistsSuccess: ["payload"],
  checkPinExistsFailure: null,
  proceedToHomeScreen: null,

  verifyPresentPin: ["payload"],
  verifyPresentPinSuccess: ["payload"],
  verifyPresentPinFailure: ["payload"],
  clearVerifyPresentPinError: null,

  confirmNewPin: ["payload"],
  confirmNewPinSuccess: null,
  confirmNewPinFailure: ["payload"],

  setInAppFaceIdPermission: ["payload"],
  setAppTheme: ["payload"],

  updateUserKycStatus: ["payload"],

  updateNonBalanceContacts: ["payload"],
  resetNonBalanceContactSearch: null,
  resetNonBalanceContactSearchResult: ["payload"],
  updatePermissionsList: ["payload"],
  updateUserSuccess: ["payload"],
  setAppEnteredOnce: ["payload"],
  fetchStoreData: null,
  fetchStoreDataSuccess: ["payload"],

  fetchSMSBooking: ["payload"],
  fetchSMSBookingLoading: ["payload"],
  fetchSMSBookingFailure: ["payload"],
  fetchSMSBookingSuccess: ["payload"],

  OpenBookingExpireWarningModal: ["payload"],
  CloseBookingExpireWarningModal: ["payload"],

  OpenBookingLoadingModal: ["payload"],
  CloseBookingLoadingModal: ["payload"],
});

export const AuthTypes = Types;
export default Creators;
