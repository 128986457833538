import { CheckoutService } from "src/services/api/checkoutService";
import { CheckoutTypes } from "src/stores/checkout/actions";
import {
  all,
  call,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import CheckoutActions from "src/stores/checkout/actions";
import { startLoadingActionHelper, stopLoadingActionHelper } from "./UtilSaga";
import _ from "lodash";
import {
  CHECKOUT_MESSAGE_STATUS,
  CHECKOUT_REDIRECT_URL,
  helperService,
} from "src/services/helperService";
import AuthActions from "src/stores/auth/actions";
function* addCard({ payload, type }) {
  try {
    yield put(startLoadingActionHelper(type));
    const response = yield call(CheckoutService.addCard, payload);
    console.log(response, "addCard");
    if (response) {
      yield put(CheckoutActions.saveAddCardResponse(response));
      // const wallet = yield select((state) => state.auth.userInfo)
      // let requestPayload = {
      //   token: response.token,
      //   transferType: response.type.toUpperCase(),
      //   amount: 0,
      //   referenceId: response.token,
      //   wallet: wallet.id,
      //   successUrl: CHECKOUT_REDIRECT_URL.CHECKOUT_SUCCESS_URL,
      //   failureUrl: CHECKOUT_REDIRECT_URL.CHECKOUT_FAILURE_URL,
      //   _3dsEnabled: true,
      // }

      // let cardVerifyResponse = yield call(verifyAndPayment, {
      //   payload: requestPayload,
      //   type: CheckoutTypes.VERIFY_AND_PAYMENT,
      // })
      // console.log(cardVerifyResponse, 'verifyAndPayment')

      // if (cardVerifyResponse.success) {
      //   const { checkoutResponse } = cardVerifyResponse.data
      //   const { message, redirectUrl } = checkoutResponse
      //   if (message === CHECKOUT_MESSAGE_STATUS.CARD_VERIFIED) {
      //     // redirect to source of fund status screen with success
      //     // navigateAndReset(NavigationContainers.AddCardStatusScreen, {
      //     //   payload: cardVerifyResponse.data.cardDetails,
      //     // })
      //     yield put(CheckoutActions.getSavedCards())
      //   } else {
      //     if (message === CHECKOUT_MESSAGE_STATUS.USER_INPUT_REQUIRED) {
      //       // navigate to a webview and add url change listeners
      //       // navigate(NavigationContainers.BrowserScreen, {
      //       //   link: redirectUrl,
      //       //   type: 'ADD_CARD',
      //       // })
      //     }
      //   }
      // } else {
      //   let errorMsg = cardVerifyResponse.error
      //     ? cardVerifyResponse.error.message
      //     : 'something went wrong'
      //   // SnackbarToast(errorMsg)
      // }
    } else {
      let errorMsg = response.error
        ? response.error.error_type
        : "Error! Please try again.";
      // SnackbarToast(errorMsg)
    }
  } catch (error) {
    // errorNotify('Checkout => addCard: ' + JSON.stringify(error))
    console.log("addCard saga catch error===>", JSON.stringify(error));
  } finally {
    yield put(stopLoadingActionHelper(type));
  }
}

function* getApplePayToken({ payload, type }) {
  try {
    yield put(startLoadingActionHelper(type));
    const response = yield call(CheckoutService.getApplePayToken, payload);
    if (response && response.success) {
      console.log("applepay success response===>", response);
      const wallet = yield select((state) => state.wallet.walletInfo);
      let requestPayload = {
        token: response.token,
        transferType: "APPLE_PAY",
        amount: Number(payload.money.value),
        referenceId: response.token,
        wallet: wallet.id,
        description: payload.note ? payload.note : "",
        successUrl: CHECKOUT_REDIRECT_URL.CHECKOUT_SUCCESS_URL,
        failureUrl: CHECKOUT_REDIRECT_URL.CHECKOUT_FAILURE_URL,
        _3dsEnabled: false,
      };

      let paymentResponse = yield call(verifyAndPayment, {
        payload: requestPayload,
        type: CheckoutTypes.VALIDATE_CARD,
      });
      if (paymentResponse.success) {
        // PlayRemoteURLSoundFile('wallet')
        // navigate(NavigationContainers.WalletTransaction, paymentResponse)
        // yield put(CommonActions.transactionComplete(paymentResponse))
        // yield put(
        //   TransactionActions.getRecentTransactionTopupSuccess(
        //     paymentResponse.data.transactionDetails
        //   )
        // )
        yield put(CheckoutActions.getSavedCards());
        // navigateAndReset(NavigationContainers.NewTransactionStatus, {
        //   type: NEW_TRANSACTION_TYPE.TOPUP,
        // })
      } else {
        let errorMsg = paymentResponse.error
          ? paymentResponse.error.message
          : "Error! Please try again.";
        // SnackbarToast(errorMsg)
      }
    } else {
      let errorMsg = response.error
        ? response.error.error_type
        : "Error! Please try again.";
      // SnackbarToast(errorMsg)
    }
  } catch (error) {
    // errorNotify('Checkout => getApplePayToken: ' + JSON.stringify(error))
    console.log("applepay saga catch error===>", JSON.stringify(error));
  } finally {
    yield put(stopLoadingActionHelper(type));
  }
}

function* verifyAndPayment({ payload }) {
  try {
    const authPayload = {
      clientId: "fd6e3c98-8daf-4256-bc47-d6256504daef",
      clientType: "BUSINESS",
      clientChannel: "Y12BOOKING",
    };
    const Authresponse = yield call(
      CheckoutService.partnerPaymentAuthorization,
      authPayload
    );
    if (Authresponse?.data) {
      yield put(
        CheckoutActions.partnerPaymentAuthorizationSuccess(Authresponse?.data)
      );
    }

    const failureCB = payload.setPaymentFailureError;
    const showLoader = payload.showLoader;
    const applePayCallBack = payload.applePayCallBack;
    const successCallBack = payload.successCallBack;
    if (payload.setPaymentFailureError) {
      delete payload.setPaymentFailureError;
    }
    if (payload.showLoader) {
      delete payload.showLoader;
    }
    if (payload.applePayCallBack) {
      delete payload.applePayCallBack;
    }
    if (payload.successCallBack) {
      delete payload.successCallBack;
    }
    let response = yield call(CheckoutService.verifiedCardRequest, payload);
    if (response && response.success) {
      helperService.setItem("partnerRequestId", response?.data.requestId);
      if (payload.transferType === "APPLE_PAY") {
        applePayCallBack(
          response?.data?.checkoutResponse?.redirectUrl,
          successCallBack
        );
        //   yield put(AuthActions.CloseBookingLoadingModal(""));
        showLoader(false);
      } else {
        yield put(CheckoutActions.verifyPaymentResponse(response?.data));
        //    yield put(AuthActions.CloseBookingLoadingModal(""));
        showLoader(false);
        return { success: true, data: response };
      }
    } else {
      console.log(response, "verifyAndPayment failure");
      failureCB && failureCB(true);
      yield put(AuthActions.CloseBookingLoadingModal(""));
      showLoader(false);
      // window.localStorage.clear()
      // window.location.reload()
      return { success: false, error: response };
    }
  } catch (error) {
    // errorNotify('Checkout => verifyAndPayment: ' + JSON.stringify(error))
    console.log("validate card issue==>", JSON.stringify(error));
    yield put(AuthActions.CloseBookingLoadingModal(""));
    return { success: false };
  }
}

function* processPayment({ payload }) {
  try {
    const history = payload.history;
    const callBack = payload.callBack;
    delete payload.history;
    delete payload.callBack;
    let response = yield call(CheckoutService.processCheckoutRequest, payload);
    console.log(response, callBack, "processPayment");
    yield put(AuthActions.CloseBookingLoadingModal(""));
    if (response && response.success) {
      // yield put(CheckoutActions.verifyPaymentResponse(response.data))
      history.push({ pathname: "/booking-status", state: response?.data });
      callBack(true);
      return response;
    } else {
      callBack(false);
      return { success: false, error: response };
    }
  } catch (error) {
    // errorNotify('Checkout => verifyAndPayment: ' + JSON.stringify(error))
    console.log("validate card issue==>", JSON.stringify(error));
    return { success: false };
  }
}

function* recordTransferRequest({ payload }) {
  try {
    let response = yield call(
      CheckoutService.recordTransferRequest,
      payload.transactionID
    );

    if (response && response.success) {
      const { checkoutResponse = {} } = response.data;
      const { message = "" } = checkoutResponse;
      switch (message) {
        case CHECKOUT_MESSAGE_STATUS.CARD_VERIFIED:
          // navigateAndReset(NavigationContainers.AddCardStatusScreen, {
          //   payload: response.data.cardDetails,
          // })
          yield put(CheckoutActions.getSavedCards());
          break;
        case CHECKOUT_MESSAGE_STATUS.PAYMENT_SUCCESS:
          // yield put(
          //   TransactionActions.getRecentTransactionTopupSuccess(response.data.transactionDetails)
          // )
          // navigateAndResetAndReplace(
          //   NavigationContainers.NewTransactionStatus,
          //   { type: NEW_TRANSACTION_TYPE.TOPUP },
          //   NavigationContainers.BrowserScreen
          // )
          // PlayRemoteURLSoundFile('wallet')
          yield put(
            CheckoutActions.updateCurrentMonthUsage(
              _.get(response, "data.currentMonthUsage", 100000)
            )
          );
          // yield put(CommonActions.transactionComplete(response))
          break;
        default:
          if (payload.type === "ADD_CARD") {
            // navigateAndReset(NavigationContainers.AddCardFailureScreen)
          } else {
            // navigateAndResetAndReplace(
            //   NavigationContainers.NewTransactionStatus,
            //   { type: NEW_TRANSACTION_TYPE.TOPUP, topupFailed: true },
            //   NavigationContainers.BrowserScreen
            // )
          }
      }
    } else {
      if (payload.type === "ADD_CARD") {
        // navigateAndReset(NavigationContainers.AddCardFailureScreen)
      } else {
        // SnackbarToast(_.capitalize(response.message) + '! Plese Try Again.')
        // navigateAndResetAndReplace(
        //   NavigationContainers.Wallet,
        //   {},
        //   NavigationContainers.BrowserScreen
        // )
      }
    }
  } catch (error) {
    // errorNotify('Checkout => recordTransferRequest: ' + JSON.stringify(error))
    console.log("error", error);
    // SnackbarToast(_.capitalize(error) + '! Plese Try Again.')
    // navigateAndResetAndReplace(NavigationContainers.Wallet, {}, NavigationContainers.BrowserScreen)
  }
}

function* walletTopup({ payload, type }) {
  yield put(startLoadingActionHelper(type));
  try {
    let response = yield call(CheckoutService.paymentRequest, payload);
    console.log(response, "walletTopup");
    if (response && response.success) {
      const { checkoutResponse = {} } = response.data;
      const { message = "", redirectUrl = "" } = checkoutResponse;
      if (message === CHECKOUT_MESSAGE_STATUS.CARD_VERIFIED) {
      } else if (message === CHECKOUT_MESSAGE_STATUS.USER_INPUT_REQUIRED) {
        // navigate(NavigationContainers.BrowserScreen, {
        //   link: redirectUrl,
        //   type: 'TOPUP',
        // })
      } else {
        // navigateAndReset(NavigationContainers.NewTransactionStatus, {
        //   type: NEW_TRANSACTION_TYPE.TOPUP,
        //   topupFailed: true,
        // })
        // navigate(NavigationContainers.WalletTransaction, response)
      }
    } else {
      let errMsg = _.get(
        response,
        "message",
        "Something went wrong.Please try again"
      );
      // SnackbarToast(errMsg)
    }
  } catch (error) {
    // errorNotify('Checkout => walletTopup: ' + JSON.stringify(error))
  } finally {
    yield put(stopLoadingActionHelper(type));
  }
}

window.pollCount = 0;
function* verifyPayByBalanceRequest({ payload }) {
  console.log("PAYLOAD", payload);
  const { id, amount, history } = payload;
  try {
    if (helperService.getItem("polling")) {
      return;
    }
    if (id) {
      const response = yield call(
        CheckoutService.getBookingDetails,
        payload.id
      );

      console.log(
        "handlePaywithBalanceFromBG",
        response?.data.status,
        "SUCCESS",
        response?.data.status === "SUCCESS",
        response,
        response.data
      );
      const resData = response?.data;

      if (response?.data.status === "SUCCESS") {
        window.pollCount++;
        if (window.pollCount > 1) {
          return;
        }
        console.log(window.pollCount, "pollCount------");
        helperService.setItem("polling", true);
        // yield put(CheckoutActions.verifyPayByBalance, resData);
        console.log(
          "enter   DWJJFNJFNEWOJFBOEWBCJLDCJLADSNCJLAN",
          window.pollCount
        );
        yield call(CheckoutService.getPayByBalanceDetails, {
          id,
          amount,
        });

        history.push({
          pathname: "/pay-by-balance-booking",
          state: { ...resData },
        });
      }

      // The browser window is now visible again (i.e., the user has switched back to the browser window)
      console.log("Browser window is now visible.");
    }
  } catch (error) {
    // errorNotify('Checkout => verifyAndPayment: ' + JSON.stringify(error))
    console.log("validate card issue==>", JSON.stringify(error));
    return { success: false };
  }
}

// payment details

export function* getPartnerPaymentDetails({ payload, type }) {
  console.log(payload);
  try {
    yield put(CheckoutActions.getPartnerPaymentDetailsLoading());
    const response = yield call(
      CheckoutService.getPartnerPaymentDetails,
      payload
    );

    if (response?.data) {
      yield put(
        CheckoutActions.getPartnerPaymentDetailsSuccess(response?.data)
      );

      helperService.setItem("getPartnerPaymentDetails", response?.data);
      localStorage.removeItem("partnerAccessToken");
    }
  } catch (err) {
    console.log(err);
    yield put(CheckoutActions.getPartnerPaymentDetailsFailure());
  }
}

export default function* CheckoutSaga() {
  yield all([
    takeLatest(CheckoutTypes.ADD_CARD, addCard),
    takeLatest(CheckoutTypes.GET_APPLE_PAY_TOKEN, getApplePayToken),
    takeLatest(CheckoutTypes.WALLET_TOPUP, walletTopup),
    takeLatest(CheckoutTypes.VERIFY_AND_PAYMENT, verifyAndPayment),
    takeLatest(CheckoutTypes.PROCESS_PAYMENT, processPayment),
    takeLatest(CheckoutTypes.RECORD_TRANSFER_REQUEST, recordTransferRequest),
    takeLatest(
      CheckoutTypes.VERIFY_PAY_BY_BALANCE_REQUEST,
      verifyPayByBalanceRequest
    ),
    takeEvery(
      CheckoutTypes.GET_PARTNER_PAYMENT_DETAILS,
      getPartnerPaymentDetails
    ),
  ]);
}
