import axios from "axios";
import { curryN, gte, is } from "ramda";
import { helperService } from "src/services/helperService";
import { cache } from "./cacheHandler";

const whiteList = ["orm/user/login/otp/resend", "orm/payment"];

function isURLInWhiteList(url) {
  for (const item of whiteList) {
    if (url.indexOf(item) > -1) {
      return true;
    }
  }
  return false;
}

function responseHandler(response) {
  // eslint-disable-next-line no-constant-condition
  console.log(
    "isURLInWhiteList(response.config.url)",
    isURLInWhiteList(response.config.url)
  );
  if (response.config.method === "GET" || response.config.method === "get") {
    if (response.config.url && !isURLInWhiteList(response.config.url)) {
      console.log("storing in cache");
      cache.store(response.config.url, JSON.stringify(response.data));
    }
  }
  return response;
}

function errorHandler(error) {
  if (error.headers.cached === true) {
    console.log("got cached data in response, serving it directly");
    return Promise.resolve(error);
  }
  if (error?.response?.status === 401) {
    window.localStorage.clear();
    helperService.navigate("/login");
  }
  return Promise.reject(error);
}

const isWithin = curryN(3, (min, max, value) => {
  const isNumber = is(Number);
  return (
    isNumber(min) &&
    isNumber(max) &&
    isNumber(value) &&
    gte(value, min) &&
    gte(max, value)
  );
});

const in200s = isWithin(200, 299);

async function errorResponseHandler(error) {
  console.log("errorResponseHandler", error);
  if (error.response) {
    // helperService.showToastError({
    //   content:
    //     error?.response?.data?.message ||
    //     (error?.response?.data?.response
    //       ? error.response?.data?.response?.message
    //       : "Error! Please try again with valid inputs."),
    // });
  }
  if (error?.response?.status === 401) {
    window.localStorage.clear();
    helperService.navigate("/login");
    // helperService.showToastError({
    //   content: "Session Time out! Please Login again",
    // });
  }
  return Promise.reject(error);
}

const apiClient = axios.create({
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  timeout: 30000,
  withCredentials: true,
});

apiClient.interceptors.request.use(function (config) {
  const { businessStoreProviderMapping: { secretKey, providerId } = {} } =
    helperService.getItem("storeDetails") || {};
  config.headers.Authorization =
    helperService?.getAuthToken()?.replace(/^"(.*)"$/, "$1") ||
    helperService.getItem("initialToken")?.replace(/^"(.*)"$/, "$1");
  config.headers["Content-Type"] = "application/json";
  config.headers["Accept"] = "application/json";
  if (secretKey && providerId) {
    config.headers["Provider-Authorization"] = secretKey;
    config.headers["Provider-Id"] = providerId;
  }
  console.log(secretKey, providerId, "OKOKOKOKO");
  if (config.method === "GET" || config.method === "get") {
    const checkIsValidResponse = cache.isValid(config.url || "");
    if (checkIsValidResponse.isValid) {
      console.log("serving cached data");
      config.headers.cached = true;
      config.data = JSON.parse(checkIsValidResponse.value || "{}");
      return Promise.reject(config);
    }
  }
  // config.headers.append("Access-Control-Allow-Origin", "http://localhost:3000");
  // config.headers.append("Access-Control-Allow-Credentials", "true");
  return config;
});

apiClient.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);

export const apiClientService = {
  apiClient,
  isWithin,
  in200s,
};
