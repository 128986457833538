export const INITIAL_STATE = {
  loader: false,
  cards: [],
  monthlyLimit: 10000,
  currentMonthUsage: 0,
  addCardResponse: {},
  verifyPaymentResponse: {},
  verifyPayByBalanceDetails: {},
  getMerchantPaymentDetailsSuccess: {},
  getMerchantPaymentDetailsLoading: false,

  partnerPaymentAuthorizationLoading: false,
  partnerPaymentAuthorizationError: false,
  partnerPaymentAuthorizationSuccess: {},

  getPartnerPaymentDetailsLoading: false,
  getPartnerPaymentDetailsError: false,
  getPartnerPaymentDetailsSuccess: {},
};
