import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import CheckoutActions from "src/stores/checkout/actions";

const usePollingPayByBalance = ({ id, amount, history }) => {
  console.log(id, amount, history, "inhooks");
  const [polling, setPolling] = useState(false);
  const [pollingDuration, setPollingDuration] = useState(0);
  const dispatch = useDispatch();

  // Function to perform polling action
  const performPolling = () => {
    // Make an API request or any other action
    // Update the state with the fetched data
    // Example: Updating with mock data
    console.log("performing polling");
    dispatch(
      CheckoutActions.verifyPayByBalanceRequest({ id, amount, history })
    );
  };

  // Run the polling action at a specified interval when polling is active
  useEffect(() => {
    let intervalId;

    if (polling) {
      intervalId = setInterval(performPolling, 3000); // API call every 3 seconds

      // Start counting the polling duration
      const startTime = Date.now();

      // Set up a timeout to stop polling after 5 minutes
      const timeoutId = setTimeout(() => {
        setPolling(false);
        clearInterval(intervalId);
        setPollingDuration(Date.now() - startTime);
      }, 5 * 60 * 1000); // 5 minutes

      return () => {
        clearTimeout(timeoutId); // Cleanup the timeout on component unmount or when polling is stopped
        clearInterval(intervalId); // Cleanup the interval on component unmount or when polling is stopped
      };
    }
  }, [polling]);

  return { setPolling };
};
export default usePollingPayByBalance;
