export const INITIAL_STATE = {
  getMerchantPaymentDetailsSuccess: {},
  getMerchantPaymentDetailsLoading: false,
  getMerchantPaymentDetailsError: false,

  paymentAuthorizationLoading: false,
  paymentAuthorizationError: false,
  paymentAuthorizationSuccess: {},

  paymentInitiateLoading: false,
  paymentInitiateError: false,
  paymentInitiateSuccess: {},

  paymentProcessLoading: false,
  paymentProcessError: false,
  paymentProcessSuccess: {},

  getPaymentDetailsLoading: false,
  getPaymentDetailsError: false,
  getPaymentDetailsSuccess: {},
};
