import { styled, Button } from "@mui/material";
import MuiAccordionSummary from "@mui/material/AccordionSummary";

export const AccordionSummary = styled((props) => (
  <MuiAccordionSummary {...props} />
))(({ theme }) => ({
  "& .MuiAccordionSummary-content": {
    margin: 0,
  },
  "&.MuiAccordionSummary-root": {
    padding: 0,
    margin: 0,
  },
  "&.MuiAccordionSummary-content": { margin: 0 },
}));

export const CalendarButton = styled(Button)(({ theme }) => ({
  width: "100%",
  padding: "10px",
  fontFamily: 'Light',
  textTransform: "none",
  fontWeight: 400,
  fontSize: "20px",
  borderRadius: "20px",
  "&.MuiButtonBase-root.MuiButton-root": {
    boxShadow: "none",
  },
}));
