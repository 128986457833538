import { put, call, select, delay } from "redux-saga/effects";

import _ from "lodash";
import { helperService } from "src/services/helperService";
import AuthActions from "src/stores/auth/actions";
import { AuthService } from "src/services/api/authService";
import { CheckoutService } from "src/services/api/checkoutService";

export function* initiateLogin({ payload, type }) {
  try {
    const { loginPayload, history } = payload;
    const response = yield call(AuthService.initiateLogin, loginPayload);
    if (response && response.success) {
      const { token = "", nextStep = "" } = response.data;
      helperService.setItem("initialToken", token);
      yield put(AuthActions.initiateLoginSuccess(response.data));
      // if (nextStep === 'OTP') {
      //   navigate('VerifyOtpScreen', {
      //     phoneNumber: _.get(payload, 'phoneNumber.subscriberNumber', ''),
      //     countryCode: _.get(payload, 'phoneNumber.countryCode', ''),
      //   })
      history.push({
        pathname: "/otp",
        state: { mobileNumber: loginPayload.phoneNumber },
      });
      // } else if (nextStep === 'INAPP_PIN') {
      //   navigate(NavigationContainers.EnterPasscode, {
      //     verifyLogin: true,
      //     phoneNumber: _.get(payload, 'phoneNumber.subscriberNumber', ''),
      //     countryCode: _.get(payload, 'phoneNumber.countryCode', ''),
      //   })
      // }
    } else {
      yield put(AuthActions.initiateLoginFailure());
      document.getElementById("login__btn")?.classList?.remove("touched");
    }
  } catch (error) {
    console.log("Auth => initiateLogin: " + JSON.stringify(error));
    yield put(AuthActions.initiateLoginFailure());
  } finally {
  }
}

export function* verifyLogin({ payload, type }) {
  try {
    const requestId = yield select((state) => state.auth.requestId);
    if (!requestId) {
      yield put(AuthActions.verifyLoginFailure());
      return;
    }
    const response = yield call(
      AuthService.verifyLogin,
      { input: payload.input, phoneNumber: payload.phoneNumber },
      {
        "Balance-Request-Id": requestId,
      }
    );

    // {
    //   input: payload.input,
    //   mobile_phone: {
    //     countryCode: payload.phoneNumber.countryCode,
    //     number: payload.phoneNumber.subscriberNumber,
    //   },
    // },
    if (response && response.success) {
      yield put(AuthActions.verifyLoginSuccess({ pin: payload.input }));
      // if (payload.successCallback) {
      //   payload.successCallback()
      // }
      // {
      //   input: payload.input,
      //   mobile_phone: {
      //     country_code: payload.phoneNumber.countryCode,
      //     number: payload.phoneNumber.subscriberNumber,
      //   },
      // },
      const responseData = response.data;
      helperService.setAuthToken(_.get(responseData, "accessToken", null));
      const { user = {}, guest = {} } = responseData || {};
      const bookingReference = helperService.getItem("bookingReference");
      const isNavigateToBookingReference =
        helperService.getItem("isNavigateToBookingReference") || false;

      if (isNavigateToBookingReference) {
        payload.history.replace("/bookingReferenceInfo");
      } else if (
        Object.keys(guest)?.length > 0 &&
        Object.keys(user)?.length > 0 &&
        user.firstName &&
        user.firstName.length > 0
      ) {
        payload.history.replace("/categories");
        yield put(AuthActions.storeGuestDataSuccess(guest));
        yield put(AuthActions.storeUserDataSuccess(user));
        helperService.setItem("guestId", guest.guestId);
      } else if (user.firstName) {
        yield put(AuthActions.storeUserDataSuccess(user));
        const personal_info = {
          first_name: user.firstName,
          last_name: user.lastName,
          mobile_phone: {
            country_code: payload?.phoneNumber?.countryCode,
            number: payload?.phoneNumber?.subscriberNumber,
          },
        };

        const profileResponse = yield call(AuthService.updateProfile, {
          personal_info,
        });
        if (profileResponse.success) {
          yield put(AuthActions.storeGuestDataSuccess(profileResponse.data));
          payload.history.replace("/categories");
          helperService.setItem("guestId", profileResponse.data.guestId);
        } else {
          //throw error
        }
      } else {
        payload.history.push({
          pathname: "/profile",
          state: {
            mobile_phone: payload?.phoneNumber?.subscriberNumber,
            country_code: payload?.phoneNumber?.countryCode,
          },
        });
      }
    } else {
      yield put(AuthActions.verifyLoginFailure());
      yield put(AuthActions.verifyPinFailure(response));
      document.getElementById("otp__button")?.classList?.remove("touched");
      if (response.errorId === "INVALID_LOGIN_REQUEST_ID") {
        console.log("Session Expired! Please Login again.");
        yield delay(500);
        window.localStorage.clear();
        window.location.reload();
        // yield put(AuthActions.logoutUser())
        //   navigateAndReset(NavigationContainers.PhoneInput, { notFromRootScreen: true })
      }
    }
  } catch (error) {
    console.log("Auth => verifyLogin: " + JSON.stringify(error));
    yield put(AuthActions.verifyLoginFailure());
  } finally {
  }
}

export function* updateUser({ payload, type }) {
  try {
    const response = yield call(AuthService.updateProfile, {
      personal_info: payload.personal_info,
    });
    if (response.success) {
      yield put(AuthActions.storeGuestDataSuccess(response.data));
      helperService.setItem("guestId", response.data.guestId);
      yield put(
        AuthActions.updateUserSuccess({
          firstName: payload.personal_info.first_name,
          lastName: payload.personal_info.last_name,
        })
      );
      payload.history.replace("/categories");
    }
  } catch (error) {
    console.log("Auth => verifyLogin: " + JSON.stringify(error));
    yield put(AuthActions.verifyLoginFailure());
  } finally {
  }
}

export function* getCategoriesList({ payload, type }) {
  try {
    const response = yield call(AuthService.categoriesList);
    if (response) {
      payload.history.replace("/categories");
    }
  } catch (error) {
    console.log("Auth => verifyLogin: " + JSON.stringify(error));
    yield put(AuthActions.verifyLoginFailure());
  } finally {
  }
}

export function* resendOtp({ type }) {
  try {
    const requestId = yield select((state) => state.auth.requestId);
    if (!requestId) {
      yield put(AuthActions.resendOtpFailure());
      return;
    }
    const response = yield call(AuthService.resendOtp, {
      "Balance-Request-Id": requestId,
    });
    if (response && response.success) {
      yield put(AuthActions.resendOtpSuccess(response.data));
    } else {
      yield put(AuthActions.resendOtpFailure());
      yield put(AuthActions.verifyPinFailure(response));
    }
  } catch (error) {
    yield put(AuthActions.resendOtpFailure());
  } finally {
  }
}

export function* fetchStoreData({ type }) {
  try {
    const response = yield call(AuthService.fetchStoreData, {});
    if (response.success) {
      helperService.setItem("storeDetails", response.data);
      yield put(AuthActions.fetchStoreDataSuccess(response.data));
    } else {
      // yield put(AuthActions.fetchStorDataSuccess({}))
    }
  } catch (error) {
    yield put(AuthActions.resendOtpFailure());
  } finally {
  }
}

export function* fetchSMSBooking({ payload, type }) {
  try {
    //  const response = yield call(AuthService.fetchSMSBooking, payload);
    const response = yield call(
      CheckoutService.getPartnerPaymentDetails,
      payload
    );
    console.log(response, "generators-------");
    if (response.success) {
      helperService.setItem("fetchSMSBooking", response.data);
      yield put(AuthActions.fetchSMSBookingSuccess(response.data));
    } else {
      // yield put(AuthActions.fetchStorDataSuccess({}))
    }
    window.localStorage.clear();
  } catch (error) {
    yield put(AuthActions.fetchSMSBookingFailure());
  } finally {
  }
}
