import React, { useEffect } from "react";
import { ReactComponent as ClockIcon } from "src/assets/images/clock.svg";
import "./styles.css";
import { Chip } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useState } from "react";
import { helperService } from "src/services/helperService";
import usePollingPayByBalance from "src/hooks/usePollingPayByBalance";
import { withRouter } from "react-router";

const BalanceProcessing = ({ location, history }) => {
  console.log(location, history);
  console.log("RENDERINGGGGGG", helperService.getItem("openPaymentLink"));

  const { paymentLink, ...payload } = location.state;
  const [time, setTime] = useState(10 * 60); // Initial time in seconds

  const { setPolling } = usePollingPayByBalance({ ...payload, history });

  useEffect(() => {
    setPolling(true);
    // if (helperService.getItem("openPaymentLink")) {
    //   return;
    // }
    const interval = setInterval(() => {
      setTime((prevTime) => prevTime - 1);
    }, 1000);

    const timerId = setTimeout(() => {
      window.localStorage.clear();
    }, 10 * 60 * 1000);

    window.open(paymentLink);

    return () => {
      window.pollCount = 0;
      //   localStorage.removeItem("openPaymentLink");
    };
  }, []);

  if (time < 0) {
    window.localStorage.clear();
    window.location.reload();
  }

  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  return (
    <div>
      <div style={{ position: "absolute", right: 35, top: 25 }}>
        <div
          style={{
            fontFamily: "Regular",
            paddingRight: "auto",
          }}
        >
          <div>Session timer</div>
          <Chip
            sx={{
              backgroundColor: "#FFA028",
              color: "white",
              ".MuiChip-label": { fontSize: "14px" },
            }}
            icon={<AccessTimeIcon fontSize="small" sx={{ fill: "white" }} />}
            label={`${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`}
          />
        </div>
      </div>
      <div className="processing-container">
        {<ClockIcon />}
        <div className="processing-heading-txt">
          {"Processing Payment"}
          <div className="processing-sub-heading-txt">
            Please do not close this page while we are processing your payment.
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(BalanceProcessing);
