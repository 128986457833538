import React from "react";
import PaymentOptions from "./payment-options";
import OrderTotal from "./order-total";
import { withRouter } from "react-router";
import { useSelector } from "react-redux";
import { useState } from "react";
import ErrorMessageQR from "src/components/ErrorMessageQR";
import queryString from "query-string";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import MerchantAction from "src/stores/merchant/actions";
import { helperService } from "src/services/helperService";
import PaymentNotFound from "src/containers/payment-not-found";
import ContentLoader from "react-content-loader";
import BookingLoaderModel from "src/containers/merchant-confirm-payment/BookingLoaderModel/BookingLoaderModel";
import Styles from "./styles.module.css";
import { ReactComponent as BalanceLogo } from "src/assets/images/Balance_Logo.svg";
import { ReactComponent as RTADubai } from "src/assets/images/RTA_Dubai.svg";
import { Box } from "@mui/material";
import apiConstants from "src/config/env";

const MerchantConfirmPayment = ({ history, location }) => {
  const [failureError, setFailureError] = useState(false);

  const {
    businessUserId = helperService.getItem("businessUserId"),
    id: paymentId = helperService.getItem("paymentId"),
  } = queryString.parse(location.search);

  console.log("IDSSSSSSS", paymentId, businessUserId);

  const dispatch = useDispatch();

  const data = useSelector(
    (state) => state.merchant?.getMerchantPaymentDetailsSuccess
  );

  const getMerchantPaymentDetailsError = useSelector(
    (state) => state.merchant.getMerchantPaymentDetailsError
  );

  const getMerchantPaymentDetailsLoading = useSelector(
    (state) => state.merchant?.getMerchantPaymentDetailsLoading
  );

  console.log("useselectorrrrrr", data);
  console.log("failureError ==> ", failureError);

  const isRTAStore = apiConstants.RTA_STORE_ID === data?.qrdto?.store?.id;

  const price = {
    billAmount: data?.merchantRequestDTO?.amount,
    convenienceAmount: data?.merchantRequestDTO?.processingFee,
    conveniencePercentage: data?.merchantRequestDTO?.convenienceFeePercentage,
    customerCarbonContribution:
      data?.merchantRequestDTO?.customerCarbonContribution ?? 0,
    merchantCarbonContribution:
      data?.merchantRequestDTO?.merchantCarbonContribution,
  };

  useEffect(() => {
    if (businessUserId && paymentId) {
      helperService.setItem("paymentId", paymentId);
      helperService.setItem("businessUserId", businessUserId);
      dispatch(
        MerchantAction.getMerchantPaymentDetails({
          businessUserId,
          paymentId,
          history,
        })
      );
    }
  }, [businessUserId, paymentId]);

  if (getMerchantPaymentDetailsError) {
    return (
      <div style={{ height: "80%", display: "flex", alignItems: "center" }}>
        <PaymentNotFound />
      </div>
    );
  }

  if (getMerchantPaymentDetailsLoading) {
    return (
      <div
        className={Styles["store-cat-container"]}
        style={{ paddingTop: "40%" }}
      >
        <ContentLoader viewBox="60 0 380 70" foregroundColor={"#999"}>
          {/* Only SVG shapes */}
          <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
          <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
        </ContentLoader>
        <ContentLoader viewBox="60 0 380 70" foregroundColor={"#999"}>
          {/* Only SVG shapes */}
          <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
          <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
        </ContentLoader>
        <ContentLoader viewBox="60 0 380 70" foregroundColor={"#999"}>
          {/* Only SVG shapes */}
          <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
          <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
        </ContentLoader>
      </div>
    );
  }

  // setFailure todo you need to for try again screen

  if (failureError) {
    return (
      <div style={{ backgroundColor: "black", width: "100%" }}>
        <ErrorMessageQR
          wrongPage={true}
          history={history}
          price={price}
          setFailureError={() => setFailureError(false)}
          isRTAStore={isRTAStore}
        />
      </div>
    );
  }

  return (
    <>
      {/* <ErrorMessage wrongPage /> */}
      <div className={Styles["store-cat-container"]}>
        <Box style={{ marginTop: "15%" }}></Box>
        {isRTAStore && (
          <Box
            display={"flex"}
            margin={"auto"}
            justifyContent={"space-between"}
            alignItems={"center"}
            width={"90%"}
          >
            <RTADubai />
            <BalanceLogo />
          </Box>
        )}

        <BookingLoaderModel />

        <div className={Styles["category-heading"]}>
          {isRTAStore ? "Your Taxi Fare" : "Make your payment"}
        </div>

        <OrderTotal
          price={price}
          showConvienience={true}
          isRTAStore={isRTAStore}
        />
        <PaymentOptions
          price={price}
          history={history}
          setFailureError={(e) => setFailureError(e)}
          isRTAStore={isRTAStore}
        />
      </div>
    </>
  );
};

export default withRouter(MerchantConfirmPayment);

// {
//   "success": true,
//   "status": "OK",
//   "data": {
//       "qrdto": {
//           "id": "03b53507-296d-4f88-80a5-52acd5591406",
//           "account": {
//               "id": "a1539b8f-49bb-4d74-9326-441966cfba43",
//               "type": "MERCHANT",
//               "wallets": []
//           },
//           "store": {
//               "id": "58d4b945-518d-4765-8935-96e68e684df7",
//               "name": "DIFCNewPlace",
//               "brand": {
//                   "id": "8a86b7a8-0872-4443-a9b1-93d43f059836",
//                   "name": "Y12",
//                   "tier": {
//                       "id": 2,
//                       "name": "Gold",
//                       "active": true,
//                       "monthlyRate": 2500,
//                       "yearlyRate": 2000,
//                       "monthlyCancellationRate": 10000,
//                       "yearlyCancellationRate": 0
//                   }
//               },
//               "balanceKey": "Y1211",
//               "costCategory": "",
//               "profilePictureUrl": "https://balancestaging.blob.core.windows.net/storelogo/58d4b945-518d-4765-8935-96e68e684df7/_1681813022963.png",
//               "logoUrl": "https://balancestaging.blob.core.windows.net/storelogo/58d4b945-518d-4765-8935-96e68e684df7/difc_light_1681813039223.png",
//               "logoUrlDark": "https://balancestaging.blob.core.windows.net/storelogo/58d4b945-518d-4765-8935-96e68e684df7/difc_dark_1681813039239.png",
//               "customBackground": "",
//               "status": "ACTIVE",
//               "website": "",
//               "description": "Y12, the first Italian, unisex hair salon in Dubai is once again innovating the industry with a brand new concept in its new location at ICD Brookfield Place in DIFC.",
//               "businessStoreCardDetails": {
//                   "id": "27d34590-57d4-4326-a776-aa02a08816dc",
//                   "backgroundColor": "#000000",
//                   "backgroundColorDark": "#FFFFFF",
//                   "fontColor": "#000000",
//                   "fontColorDark": "#FFFFFF",
//                   "overlapLogo": "https://balancestaging.blob.core.windows.net/privilegescards/store/58d4b945-518d-4765-8935-96e68e684df7/overlaplogo_light_1681812998855.png",
//                   "overlapLogoDark": "https://balancestaging.blob.core.windows.net/privilegescards/store/58d4b945-518d-4765-8935-96e68e684df7/overlaplogo_dark_1681812998905.png",
//                   "heroBanner": "https://balancestaging.blob.core.windows.net/privilegescards/store/58d4b945-518d-4765-8935-96e68e684df7/herobanner_1681812998919.png",
//                   "screenSaver": "https://balancestaging.blob.core.windows.net/privilegescards/store/58d4b945-518d-4765-8935-96e68e684df7/screensaver_1690457997922.mp4"
//               }
//           },
//           "businessUser": {
//               "id": "72f776cb-44f5-4ee5-92be-8d0fd50403e6",
//               "fullName": "Sarbjot Singh",
//               "loginId": "sarbjotExec",
//               "role": "EXECUTIVE",
//               "status": "ACTIVE",
//               "phoneCountryCode": "971",
//               "phoneSubscriberNumber": "53130012"
//           },
//           "createdAt": 1699528992000,
//           "updatedAt": 1699528992000
//       },
//       "merchantRequestDTO": {
//           "id": "79472cbb-30ff-4b1c-a3fa-8b3265b26a3b",
//           "creatorWallet": {
//               "id": "72859076-7572-4d05-b310-8ff29267c627",
//               "currency": "AED",
//               "blockedBalance": 48638350,
//               "balance": 49151431,
//               "points": 0,
//               "blockedPoints": 0,
//               "category": "PRIMARY",
//               "cashoutStatus": "AVAILABLE",
//               "createdAt": 1681812692000,
//               "updatedAt": 1697456334000
//           },
//           "amount": 1900,
//           "discountedAmount": 0,
//           "processingFeePercentage": 0,
//           "processingFee": 0,
//           "convenienceFeePercentage": 300,
//           "convenienceFee": 60,
//           "offers": [],
//           "createdBy": {
//               "id": "72f776cb-44f5-4ee5-92be-8d0fd50403e6",
//               "fullName": "Sarbjot Singh",
//               "loginId": "sarbjotExec",
//               "role": "EXECUTIVE",
//               "status": "ACTIVE",
//               "phoneCountryCode": "971",
//               "phoneSubscriberNumber": "53130012"
//           },
//           "status": "PENDING"
//       }
//   }
// }
