import { INITIAL_STATE } from "./initialState";
import { createReducer } from "reduxsauce";
import { MerchantTypes } from "./actions";

export const getMerchantPaymentDetailsLoading = (state, { payload }) => {
  return {
    ...state,
    getMerchantPaymentDetailsLoading: true,
    getMerchantPaymentDetailsError: false,
  };
};

export const getMerchantPaymentDetailsFailure = (state, { payload }) => {
  return {
    ...state,
    getMerchantPaymentDetailsLoading: false,
    getMerchantPaymentDetailsError: true,
  };
};

export const getMerchantPaymentDetailsSuccess = (state, { payload }) => {
  return {
    ...state,
    getMerchantPaymentDetailsSuccess: payload,
    getMerchantPaymentDetailsLoading: false,
    getMerchantPaymentDetailsError: false,
  };
};

// authorization
export const paymentAuthorizationLoading = (state, { payload }) => {
  return {
    ...state,
    paymentAuthorizationLoading: true,
    paymentAuthorizationError: false,
  };
};

export const paymentAuthorizationFailure = (state, { payload }) => {
  return {
    ...state,
    paymentAuthorizationLoading: false,
    paymentAuthorizationError: true,
  };
};

export const paymentAuthorizationSuccess = (state, { payload }) => {
  return {
    ...state,
    paymentAuthorizationSuccess: payload,
    paymentAuthorizationLoading: false,
    paymentAuthorizationError: false,
  };
};

//initiate

export const paymentInitiateLoading = (state, { payload }) => {
  return {
    ...state,
    paymentInitiateLoading: true,
    paymentInitiateError: false,
  };
};

export const paymentInitiateFailure = (state, { payload }) => {
  return {
    ...state,
    paymentInitiateLoading: false,
    paymentInitiateError: true,
  };
};

export const paymentInitiateSuccess = (state, { payload }) => {
  return {
    ...state,

    paymentInitiateSuccess: payload,
    paymentInitiateLoading: false,
    paymentInitiateError: false,
  };
};

// process
export const paymentProcessLoading = (state, { payload }) => {
  return {
    ...state,
    paymentProcessLoading: true,
    paymentProcessError: false,
  };
};

export const paymentProcessFailure = (state, { payload }) => {
  return {
    ...state,
    paymentProcessLoading: false,
    paymentProcessError: true,
  };
};

export const paymentProcessSuccess = (state, { payload }) => {
  return {
    ...state,
    paymentProcessSuccess: payload,
    paymentProcessLoading: false,
    paymentProcessError: false,
  };
};

// payment details
export const getPaymentDetailsLoading = (state, { payload }) => {
  return {
    ...state,
    getPaymentDetailsLoading: true,
    getPaymentDetailsFailure: false,
  };
};

export const getPaymentDetailsFailure = (state, { payload }) => {
  return {
    ...state,
    getPaymentDetailsLoading: false,
    getPaymentDetailsFailure: true,
  };
};

export const getPaymentDetailsSuccess = (state, { payload }) => {
  return {
    ...state,
    getPaymentDetailsSuccess: payload,
    getPaymentDetailsLoading: false,
    getPaymentDetailsFailure: false,
  };
};

export const reducer = createReducer(INITIAL_STATE, {
  [MerchantTypes.GET_MERCHANT_PAYMENT_DETAILS_LOADING]:
    getMerchantPaymentDetailsLoading,
  [MerchantTypes.GET_MERCHANT_PAYMENT_DETAILS_FAILURE]:
    getMerchantPaymentDetailsFailure,
  [MerchantTypes.GET_MERCHANT_PAYMENT_DETAILS_SUCCESS]:
    getMerchantPaymentDetailsSuccess,

  [MerchantTypes.PAYMENT_AUTHORIZATION_LOADING]: paymentAuthorizationLoading,
  [MerchantTypes.PAYMENT_AUTHORIZATION_FAILURE]: paymentAuthorizationFailure,
  [MerchantTypes.PAYMENT_AUTHORIZATION_SUCCESS]: paymentAuthorizationSuccess,

  [MerchantTypes.PAYMENT_INITIATE_LOADING]: paymentInitiateLoading,
  [MerchantTypes.PAYMENT_INITIATE_FAILURE]: paymentInitiateFailure,
  [MerchantTypes.PAYMENT_INITIATE_SUCCESS]: paymentInitiateSuccess,

  [MerchantTypes.PAYMENT_PROCESS_LOADING]: paymentProcessLoading,
  [MerchantTypes.PAYMENT_PROCESS_FAILURE]: paymentProcessFailure,
  [MerchantTypes.PAYMENT_PROCESS_SUCCESS]: paymentProcessSuccess,

  [MerchantTypes.GET_PAYMENT_DETAILS_LOADING]: getPaymentDetailsLoading,
  [MerchantTypes.GET_PAYMENT_DETAILS_FAILURE]: getPaymentDetailsFailure,
  [MerchantTypes.GET_PAYMENT_DETAILS_SUCCESS]: getPaymentDetailsSuccess,
});
