import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { reducer as ExampleReducer } from "./Example/Reducers";
import { reducer as AuthReducer } from "./auth/reducers";
import { reducer as CheckoutReducer } from "./checkout/reducer";
import { reducer as MerchantReducer } from "./merchant/reducer";
import { persistReducer } from "redux-persist";
import createSagaMiddleware from "redux-saga";
import logger from "redux-logger";
import storage from "redux-persist/lib/storage";

const appReducer = combineReducers({
  example: ExampleReducer,
  auth: AuthReducer,
  checkout: CheckoutReducer,
  merchant: MerchantReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    state = undefined;
  }
  return appReducer(state, action);
};

const blacklistPaths = ["example"];

const rootPersistConfig = {
  key: "partner-website",
  debounce: 100,
  storage: storage,
  blacklist: blacklistPaths,
  whitelist: [],
};

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);
let middleWares = [];
let enhancers = [];
const sagaMiddleware = createSagaMiddleware();
middleWares.push(sagaMiddleware);

/* Following logic enables logger in only development mode in any environment */
// if (__DEV__) {
//   enhancers.push(applyMiddleware(...middleWares, logger))
// } else {
//   enhancers.push(applyMiddleware(...middleWares))
// }

enhancers.push(applyMiddleware(...middleWares, logger));

const store = createStore(persistedReducer, compose(...enhancers));

export { sagaMiddleware, store };
