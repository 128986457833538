// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.processing-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-inline: 60px;
}

.processing-heading-txt {
  font-family: "Light";
  font-size: 32px;
  line-height: 36px;
  text-align: center;
  padding-top: 42px;
  padding-inline: 10px;
}

.processing-sub-heading-txt {
  font-family: "Light";
  font-size: 18px;
  line-height: 24px;
  padding-top: 32px;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/containers/balance-processing/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,oBAAoB;AACtB;;AAEA;EACE,oBAAoB;EACpB,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;EACE,oBAAoB;EACpB,eAAe;EACf,iBAAiB;EACjB,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":[".processing-container {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n  padding-inline: 60px;\n}\n\n.processing-heading-txt {\n  font-family: \"Light\";\n  font-size: 32px;\n  line-height: 36px;\n  text-align: center;\n  padding-top: 42px;\n  padding-inline: 10px;\n}\n\n.processing-sub-heading-txt {\n  font-family: \"Light\";\n  font-size: 18px;\n  line-height: 24px;\n  padding-top: 32px;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
