import React from "react";
import AddToCalendarButtonTooltip from "./Tooltip/AddToCalendarButtonTooltip";
import { ReactComponent as CalendarIcon } from "./../../assets/images/calendar.svg";
import {
  createTheme,
  ThemeProvider,
  Accordion,
  AccordionDetails,
} from "@mui/material";
import { AccordionSummary, CalendarButton } from "./Styles.jsx";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0095F6",
    },
  },
});

export default function AddToCalendarButton({ calendarEvent }) {
  const [isTooltipVisible, setIsTooltipVisible] = React.useState(false);

  return (
    <ThemeProvider theme={theme}>
      <Accordion
        disableGutters
        sx={{
          backgroundColor: "#f1f4f6",
          "&.MuiAccordion-root": {
            padding: 0,
            borderRadius: "20px",
            border: isTooltipVisible ? "1px solid #0095F6" : "none",
            boxShadow: "none",
            marginTop: '18px'
          },
        }}
        onChange={() => setIsTooltipVisible((prev) => !prev)}
      >
        <AccordionSummary>
          <CalendarButton
            variant="contained"
            color="primary"
            startIcon={<CalendarIcon />}
          >
            Add to Calendar
          </CalendarButton>
        </AccordionSummary>
        <AccordionDetails>
          <AddToCalendarButtonTooltip calendarEvent={calendarEvent} />
        </AccordionDetails>
      </Accordion>
    </ThemeProvider>
  );
}
